
// Accordion / Collapsible list

.panel,
.panel-group {
    .panel-heading {
        .panel-title {
            [data-toggle="collapse"] {
                padding: 0;
                margin: 0;
                font: $font-sm;

                .caret {
                    margin-left: $spacing-8;
                    top: -$spacing-2;
                    position: relative;
                }
            }
        }

        // arrow styling for 'toggle' trigger
        [data-toggle="collapse"] {
            .caret {
                margin-right: rem(1px);

                &.caret-large-blue { background-color: var(--caret-primary-bg); }

                &:not([class*='caret-']) { background-color: var(--caret-default-bg); }
            }

            &:hover {
                .caret {
                    &.caret-large-blue { background-color: var(--anchor-hover-color); }

                    &:not([class*='caret-']) { background-color: var(--anchor-hover-color); }
                }
            }

            &:not(.collapsed) {
                // large caret
                .caret {
                    &[class*=caret-large] {
                        transform: rotate(180deg);
                    }
                }

            }
        }
    }

    .panel-body {
        .panel-heading + .panel-collapse {
            .panel-body {
                padding-left: rem(22px);
                padding-bottom: rem(12px);
            }
        }
    }
}

.panel-group {
    [data-toggle="collapse"] {
        display: inline-block;
        font-weight: $normal;
    }

    > .panel {
        .panel-body {
            padding-top: rem(20px);

            h3 {
                font-weight: $bold;
                font-size: $font-size-base;
            }
        }

        &:first-child {
            > .panel-heading {
                padding-top: $spacing-16;
            }
        }

        &:last-child {
            > .panel-heading .collapsed {
                margin-bottom: rem(18px);
            }
        }

        > .panel-collapse {
            > .panel-body {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }

    .panel-collapse {
        .panel-heading {
            padding-top: 1.5rem;

            .panel-title {
                [data-toggle="collapse"] {
                    &:not(.collapsed) {
                        //font-weight: $bold;
                        .caret { transform: rotate(180deg); }
                    }

                    .caret { 
                        margin-left: rem(0); 
                        margin-right: rem(12px); 
                    }
                }
            }
        }

        &.collapse {
            display: none;

            &.in {
                display: block;
                margin-top: rem(4px);
            }
        }

        > .panel-body {
            .panel-collapse.collapse.in {

                &:after,
                &:before {
                    content: "";
                    display: block;
                    height: 100%;
                    position: absolute;
                    top: rem(1px);
                    left: -$spacing-32;
                }

                &:after {
                    background: var(--module-container-active-bg-color);
                    width: calc((#{$spacing-32} * 2 ) + 100%);
                    z-index: 0;
                }

                &:before {
                    background: var(--module-container-active-before-color);
                    width: rem(5px);
                    z-index: 1;
                }

                .panel-body {
                    z-index: 1;
                    position: relative;
                }
            }

            h4.panel-title {
                z-index: 1;
                position: relative;
            }

            > .panel { 
                position: relative;
                background: transparent;
            }
        }
    }
}
