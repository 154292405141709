/**** Individual icons - worksurface-dynamic icons ****/
.vismaicon-dynamic {
    &.vismaicon-print {
        &:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_print.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_print.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_print.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_print.svg');
        }
    }

    &.vismaicon-edit {
        &:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_edit.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_edit.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_edit.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_edit.svg');
        }
    }

    &.vismaicon-save {
        &:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_save.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_save.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_save.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_save.svg');
        }
    }

    &.vismaicon-delete {
        &:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_delete.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_delete.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_delete.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_delete.svg');
        }
    }

    &.vismaicon-attach {
        &:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_attach.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_attach.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_attach.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_attach.svg');
        }
    }

    &.vismaicon-briefcase {
        &:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_briefcase.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_briefcase.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_briefcase.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_briefcase.svg');
        }
    }

    &.vismaicon-new {
        &:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_new.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_new.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_new.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_new.svg');
        }
    }

    &.vismaicon-comment {
        &:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_comment.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_comment.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_comment.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_comment.svg');
        }
    }

    &.vismaicon-unread-comment {
        &:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_unread_comment.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_unread_comment.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_unread_comment.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_unread_comment.svg');
        }
    }

    &.vismaicon-add-comment {
        &:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_add_comment.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_add_comment.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_add_comment.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_add_comment.svg');
        }
    }

    &.vismaicon-zoom-in {
        &:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_zoom_in.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_zoom_in.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_zoom_in.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_zoom_in.svg');
        }
    }

    &.vismaicon-zoom-out {
        &:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_zoom_out.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_zoom_out.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_zoom_out.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_zoom_out.svg');
        }
    }

    &.vismaicon-undo {
        &:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_undo.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_undo.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_undo.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_undo.svg');
        }
    }

    &.vismaicon-redo {
        &:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_redo.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_redo.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_redo.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_redo.svg');
        }
    }

    &.vismaicon-flag {
        &:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_flag.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_flag.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_flag.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_flag.svg');
        }
    }

    &.vismaicon-cut {
        &:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_cut.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_cut.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_cut.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_cut.svg');
        }
    }

    &.vismaicon-copy {
        &:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_copy.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_copy.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_copy.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_copy.svg');
        }
    }

    &.vismaicon-paste {
        &:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_paste.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_paste.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_paste.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_paste.svg');
        }
    }

    &.vismaicon-report {
        &:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_report.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_report.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_report.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_report.svg');
        }
    }

    &.vismaicon-home {
        &:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_home.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_home.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_home.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_home.svg');
        }
    }

    &.vismaicon-refresh {
        &:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_refresh.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_refresh.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_refresh.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_refresh.svg');
        }
    }

    &.vismaicon-employee,
    &.vismaicon-user {
        &:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_user.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_user.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_user.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_user.svg');
        }
    }

    &.vismaicon-users {
        &:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_users.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_users.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_users.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_users.svg');
        }
    }

    &.vismaicon-time {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_time.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_time.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_time.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_time.svg');
        }
    }

    &.vismaicon-reminder {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_reminder.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_reminder.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_reminder.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_reminder.svg');
        }
    }

    &.vismaicon-search {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_search.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_search.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_search.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_search.svg');
        }
    }

    &.vismaicon-note {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_note.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_note.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_note.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_note.svg');
        }
    }

    &.vismaicon-graph,
    &.vismaicon-graphs {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_graph.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_graph.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_graph.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_graph.svg');
        }
    }

    &.vismaicon-email {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_email.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_email.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_email.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_email.svg');
        }
    }

    &.vismaicon-phone {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_phone.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_phone.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_phone.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_phone.svg');
        }
    }

    &.vismaicon-locked {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_locked.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_locked.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_locked.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_locked.svg');
        }
    }

    &.vismaicon-unlocked {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_unlocked.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_unlocked.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_unlocked.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_unlocked.svg');
        }
    }

    &.vismaicon-price,
    &.vismaicon-money {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_money.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_money.svg');
    }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_money.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_money.svg');
    }
    }

    &.vismaicon-web {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_web.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_web.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_web.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_web.svg');
        }
    }

    &.vismaicon-open {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_open.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_open.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_open.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_open.svg');
        }
    }

    &.vismaicon-upload {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_upload.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_upload.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_upload.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_upload.svg');
        }
    }

    &.vismaicon-download {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_download.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_download.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_download.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_download.svg');
        }
    }

    &.vismaicon-preview {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_preview.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_preview.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_preview.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_preview.svg');
        }
    }

    &.vismaicon-favourite {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_favourite.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_favourite.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_favourite.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_favourite.svg');
        }
    }

    &.vismaicon-like {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_like.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_like.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_like.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_like.svg');
        }
    }

    &.vismaicon-dislike {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_dislike.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_dislike.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_dislike.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_dislike.svg');
        }
    }

    &.vismaicon-filter {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_filter.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_filter.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_filter.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_filter.svg');
        }
    }

    &.vismaicon-office-word {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_word.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_word.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_word.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_word.svg');
        }
    }

    &.vismaicon-office-powerpoint {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_powerpoint.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_powerpoint.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_powerpoint.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_powerpoint.svg');
        }
    }

    &.vismaicon-office-excel {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_excel.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_excel.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_excel.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_excel.svg');
        }
    }

    &.vismaicon-export {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_export.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_export.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_export.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_export.svg');
        }
    }

    &.vismaicon-pdf {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_pdf.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_pdf.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_pdf.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_pdf.svg');
        }
    }

    &.vismaicon-changelog {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_changelog.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_changelog.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_changelog.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_changelog.svg');
        }
    }

    &.vismaicon-replace {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_replace.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_replace.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_replace.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_replace.svg');
        }
    }

    &.vismaicon-drag {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_drag.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_drag.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_drag.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_drag.svg');
        }
    }

    &.vismaicon-image-document {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_image_document.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_image_document.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_image_document.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_image_document.svg');
        }
    }

    &.vismaicon-xml-document {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_xml_document.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_xml_document.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_xml_document.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_xml_document.svg');
        }
    }

    &.vismaicon-add-user {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_add_user.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_add_user.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_add_user.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_add_user.svg');
        }
    }

    &.vismaicon-alert {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_alert.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_alert.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_alert.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_alert.svg');
        }
    }

    &.vismaicon-announcement {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_announcement.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_announcement.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_announcement.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_announcement.svg');
        }
    }

    &.vismaicon-archive-document {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_archive_document.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_archive_document.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_archive_document.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_archive_document.svg');
        }
    }

    &.vismaicon-breadcrumb-folder {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_breadcrumb_folder.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_breadcrumb_folder.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: none;
            mask-image: none;
        }
    }

    &.vismaicon-contract {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_contract.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_contract.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_contract.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_contract.svg');
        }
    }

    &.vismaicon-database {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_database.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_database.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: none;
            mask-image: none;
        }
    }

    &.vismaicon-detach {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_detach.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_detach.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_detach.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_detach.svg');
        }
    }

    &.vismaicon-document-info {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_document_info.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_document_info.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_document_info.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_document_info.svg');
        }
    }

    &.vismaicon-education {
        &:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_education.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_education.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_education.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_education.svg');
        }
    }

    &.vismaicon-enter-full-screen {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_enter_full_screen.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_enter_full_screen.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_enter_full_screen.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_enter_full_screen.svg');
        }
    }

    &.vismaicon-exit-full-screen {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_exit_full_screen.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_exit_full_screen.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_exit_full_screen.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_exit_full_screen.svg');
        }
    }

    &.vismaicon-evaluate {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_evaluate.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_evaluate.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_evaluate.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_evaluate.svg');
        }
    }

    &.vismaicon-exclude {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_exclude.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_exclude.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_exclude.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_exclude.svg');
        }
    }

    &.vismaicon-folder-building {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_folder_building.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_folder_building.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_folder_building.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_folder_building.svg');
        }
    }

    &.vismaicon-hourglass {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_hourglass.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_hourglass.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_hourglass.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_hourglass.svg');
        }
    }

    &.vismaicon-link {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_link.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_link.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_link.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_link.svg');
        }
    }

    &.vismaicon-location {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_location.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_location.svg');
        }
        &.vismaicon-sm:before {
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_location.svg');
            -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_location.svg');
        }
    }

    &.vismaicon-logon-failed {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_logon_failed.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_logon_failed.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_logon_failed.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_logon_failed.svg');
        }
    }

    &.vismaicon-lunch {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_lunch.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_lunch.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_lunch.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_lunch.svg');
        }
    }

    &.vismaicon-note-blank {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_note_blank.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_note_blank.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_note_blank.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_note_blank.svg');
        }
    }

    &.vismaicon-note-filled {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_note_filled.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_note_filled.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_note_filled.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_note_filled.svg');
        }
    }

    &.vismaicon-office-building {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_office_building.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_office_building.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_office_building.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_office_building.svg');
        }
    }

    &.vismaicon-panel {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_panel.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_panel.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_panel.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_panel.svg');
        }
    }

    &.vismaicon-price {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_price.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_price.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_price.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_price.svg');
        }
    }

    &.vismaicon-rotate-ccw {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_rotate_ccw.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_rotate_ccw.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_rotate_ccw.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_rotate_ccw.svg');
        }
    }
        
    &.vismaicon-rotate-cw {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_rotate_cw.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_rotate_cw.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_rotate_cw.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_rotate_cw.svg');
        }
    }

    &.vismaicon-secret-document {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_secret_document.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_secret_document.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_secret_document.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_secret_document.svg');
        }
    }

    &.vismaicon-send {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_send.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_send.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_send.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_send.svg');
        }
    }

    &.vismaicon-sending-failed {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_sending_failed.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_sending_failed.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_sending_failed.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_sending_failed.svg');
        }
    }

    &.vismaicon-shared {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_shared_with_me.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_shared_with_me.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_shared_with_me.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_shared_with_me.svg');
        }
    }

    &.vismaicon-sound {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_sound.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_sound.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_sound.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_sound.svg');
        }
    }

    &.vismaicon-text-document {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_text_document.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_text_document.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_text_document.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_text_document.svg');
        }
    }

    &.vismaicon-undefined-document {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_undefined_document.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_undefined_document.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_undefined_document.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_undefined_document.svg');
        }
    }

    &.vismaicon-user-folder {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_user_folder.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_user_folder.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_user_folder.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_user_folder.svg');
        }
    }

    &.vismaicon-user-placeholder {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_user_placeholder.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_user_placeholder.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_user_placeholder.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_user_placeholder.svg');
        }
    }

    &.vismaicon-video {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_video.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_video.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_video.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_video.svg');
        }
    }

    &.vismaicon-xml {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_xml.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_xml.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_xml.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_xml.svg');
        }
    }

    &.vismaicon-filter-column {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_filter_column.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_filter_column.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_filter_column.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_filter_column.svg');
        }
    }

    &.vismaicon-agreement {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_agreement.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_agreement.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_agreement.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_agreement.svg');
        }
    }

    &.vismaicon-change-owner {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_change_owner.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_change_owner.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_change_owner.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_change_owner.svg');
        }
    }

    &.vismaicon-break,
    &.vismaicon-coffee-break {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_coffee_break.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_coffee_break.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_coffee_break.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_coffee_break.svg');
        }
    }

    &.vismaicon-comment-chat {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_comment_chat.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_comment_chat.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_comment_chat.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_comment_chat.svg');
        }
    }

    &.vismaicon-comment-community {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_comment_community.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_comment_community.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_comment_community.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_comment_community.svg');
        }
    }

    &.vismaicon-comment-web-reply {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_comment_web_reply.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_comment_web_reply.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_comment_web_reply.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_comment_web_reply.svg');
        }
    }

    &.vismaicon-jira {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_jira.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_jira.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_jira.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_jira.svg');
        }
    }

    &.vismaicon-list-view {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_list_view.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_list_view.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_list_view.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_list_view.svg');
        }
    }

    &.vismaicon-payroll-calculations {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_payroll_calculations.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_payroll_calculations.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_payroll_calculations.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_payroll_calculations.svg');
        }
    }

    &.vismaicon-platform-cloud {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_platform_cloud.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_platform_cloud.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_platform_cloud.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_platform_cloud.svg');
        }
    }

    &.vismaicon-platform-mobile {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_platform_mobile.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_platform_mobile.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_platform_mobile.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_platform_mobile.svg');
        }
    }

    // &.vismaicon-sort-list {
    //     &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_sort_list.svg');
    //         mask-image: url('#{$icons-path}/worksurface-dynamic/24_sort_list.svg');
    //     }
    //     &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_sort_list.svg');
    //         mask-image: url('#{$icons-path}/worksurface-dynamic/16_sort_list.svg');
    //     }
    // }

    &.vismaicon-storage {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_storage.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_storage.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_storage.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_storage.svg');
        }
    }

    &.vismaicon-tile-view {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_tile_view.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_tile_view.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_tile_view.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_tile_view.svg');
        }
    }

    // &.vismaicon-help {
    //     &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_help.svg');
    //         mask-image: url('#{$icons-path}/worksurface-dynamic/24_help.svg');
    //     }
    //     &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_help.svg');
    //         mask-image: url('#{$icons-path}/worksurface-dynamic/16_help.svg');
    //     }
    // }

    &.vismaicon-alarm {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_alarm.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_alarm.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_pdf.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_pdf.svg');
        }
    }

    &.vismaicon-calendar { 
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_calendar.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_calendar.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_calendar.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_calendar.svg');
        }
    }

    &.vismaicon-document_production {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_document_production.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_document_production.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_document_production.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_document_production.svg');
        }
    }

    &.vismaicon-info {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_info.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_info.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_info.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_info.svg');
        }
    }

    &.vismaicon-library {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_library.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_library.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_library.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_library.svg');
        }
    }

    &.vismaicon-logout {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_logout.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_logout.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_logout.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_logout.svg');
        }
    }

    &.vismaicon-pin {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_pin.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_pin.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_pin.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_pin.svg');
        }
    }

    &.vismaicon-settings {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_settings.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_settings.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_settings.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_settings.svg');
        }
    }

    &.vismaicon-shopping_cart {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_shopping_cart.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_shopping_cart.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_shopping_cart.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_shopping_cart.svg');
        }
    }

    &.vismaicon-tools {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_tools.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_tools.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_tools.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_tools.svg');
        }
    }

    &.vismaicon-user_settings {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_user_settings.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_user_settings.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_user_settings.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_user_settings.svg');
        }
    }

    &.vismaicon-summarize {
        &:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/24_summarize.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/24_summarize.svg');
        }
        &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface-dynamic/16_summarize.svg');
            mask-image: url('#{$icons-path}/worksurface-dynamic/16_summarize.svg');
        }
    }



    /**** Interface controls icons ****/
    &.vismaicon-datepicker {
        &:before {
            -webkit-mask-image: url('#{$icons-path}/interface-controls-dynamic/datepicker.svg');
            mask-image: url('#{$icons-path}/interface-controls-dynamic/datepicker.svg');
        }
    }

    &.vismaicon-autocomplete {
        &:before {
            -webkit-mask-image: url('#{$icons-path}/interface-controls-dynamic/autocomplete.svg');
            mask-image: url('#{$icons-path}/interface-controls-dynamic/autocomplete.svg');
        }
    }

    &.vismaicon-multiselection {
        &:before {
            -webkit-mask-image: url('#{$icons-path}/interface-controls-dynamic/multiselection.svg');
            mask-image: url('#{$icons-path}/interface-controls-dynamic/multiselection.svg');
        }
    }

    &.vismaicon-datepicker,
    &.vismaicon-multiselection,
    &.vismaicon-autocomplete {
        &::before {
            width: rem(20px);
            height: rem(20px);
            -webkit-mask-position: center;
            mask-position: center;
        }
    }
}