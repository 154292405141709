
/*
 * Badges/Labels
 */

.badge {
    position: relative;
    min-width: rem(30px);
    top: 0;
    padding: rem(2px) rem(8px);
    margin-right: rem(1px);

    text-align: center;
    font-size: rem(12px);
    font-weight: $bold;
    line-height: rem(16px);

    border-radius: rem(20px);

    &-sm {
        padding: rem(1px) rem(6px);
        font-size: rem(10px);
        line-height: rem(14px);
        border-radius: rem(5px);
    }

    &,
    &-primary {
        color: var(--badge-text-color);
        background-color: var(--badge-bg);
        box-shadow: var(--badge-shadow);
    }

    &-secondary,
    &-success {
        color:  var(--badge-success-text);
        background-color: var(--badge-success-bg);
        box-shadow: var(--badge-success-shadow);
    }

    &-info {
        color: $neutral-05;
        background-color: var(--badge-info-bg);
    }

    &-warning {
        color: $neutral-05;
        background-color: var(--badge-warning-bg);
    }

    &-danger {
        color: $neutral-05;
        background-color: var(--badge-danger-bg);
        box-shadow: var(--badge-danger-shadow);
    }

    a & {
        display: inline-block;
        margin-left: rem(6px);
        text-decoration: none;
    }

    .btn &, .nav-pills & {
        margin-left: rem(6px);
        box-shadow: none;
    }

    .btn:not(.btn-primary) &, .nav-pills & {
        color: $neutral-05;

        &:not(.badge-secondary):not(.badge-success):not(.badge-info):not(.badge-warning):not(.badge-danger) {
            background-color: $primary-green;
        }
    }

    .btn.btn-primary &,
    .nav-pills.nav-pills-primary .active & {
        background-color: $neutral-05;

        &:not(.badge-secondary):not(.badge-success):not(.badge-info):not(.badge-warning):not(.badge-danger) {
            background-color: $neutral-05;
        }

        &-success, &-secondary {
            color: $primary-green;
        }

        &-info {
            color: $primary-blue;
        }

        &-warning {
            color: $primary-orange;
        }

        &-danger {
            color: $primary-red;
        }
    }

    .nav-pills.nav-pills-primary .active & {
        &:not(.badge-secondary):not(.badge-success):not(.badge-info):not(.badge-warning):not(.badge-danger) {
            color: $primary-neutral;
        }
    }
}

.badge.badge {
    .disabled &, :disabled & {
        color: $neutral-60;

        &-success, &-secondary {
            color: $green-20;
            background-color: $green-40;
        }

        &-info {
            color: $blue-10;
            background-color: $blue-20;
        }

        &-warning {
            color: $orange-20;
            background-color: $orange-40;
        }

        &-danger {
            color: $red-20;
            background-color: $red-30;
        }
    }

    .disabled .btn.btn-primary &,
    :disabled .btn.btn-primary &,
    .btn.btn-primary:disabled &,
    .btn.btn-primary.disabled & {
        color: $neutral-60;
        background-color: $neutral-05;

        &-success, &-secondary {
            color: $green-40;
        }

        &-info {
            color: $blue-20;
        }

        &-warning {
            color: $orange-40;
        }

        &-danger {
            color: $red-30;
        }
    }
}

.badge {
    :disabled .btn:not(.btn-primary) &,
    .disabled .btn:not(.btn-primary) &,
    .btn:not(.btn-primary):disabled &,
    .btn:not(.btn-primary).disabled &,
    :disabled .nav-pills &,
    .disabled .nav-pills &,
    .nav-pills:disabled &,
    .nav-pills.disabled &,
    .nav-pills :disabled &,
    .nav-pills .disabled & {
        &:not(.badge-secondary):not(.badge-success):not(.badge-info):not(.badge-warning):not(.badge-danger) {
            color: $green-20;
            background-color: $green-40;
        }
    }
}

.label {
    position: relative;
    margin: 0;
    padding: rem(3px) rem(12px) rem(4px);

    color: var(--text-default);
    font-size: rem(12px);
    font-weight: $normal;
    line-height: rem(16px);

    border-radius: rem(4px);
    border: 0;
    background-color: var(--button-bg);
    box-shadow: inset 0 0 0 rem(1px) var(--button-border-color);

    &-secondary,
    &-success {
        color:  var(--alert-success-text);
        background-color: var(--alert-success-bg);
        box-shadow: inset 0 0 0 rem(1px) var(--alert-success-border);
    }

    &-primary,
    &-info {
        color: var(--alert-info-text);
        background-color: var(--alert-info-bg);
        box-shadow: inset 0 0 0 rem(1px) var(--alert-info-border);
    }

    &-warning {
        color: var(--alert-warning-text);
        background-color: var(--alert-warning-bg);
        box-shadow: inset 0 0 0 rem(1px) var(--alert-warning-border);
    }

    &-danger {
        color: var(--alert-danger-text);
        background-color: var(--alert-danger-bg);
        box-shadow: inset 0 0 0 rem(1px) var(--alert-danger-border);
    }
}
