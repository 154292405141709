
//# Table
.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: rem(18px);
    border-spacing: 0;
    border-collapse: separate;

    box-shadow: $shadow-10 var(--table-shadow-color);

    > caption {
        text-align: left;
        padding: rem(8px) rem(17px);
        }

    > thead, > tbody, > tfoot {
        background: var(--table-bg);

        > tr {
            > th, > td {
                padding-left: rem(17px);
                padding-right: rem(27px);
                }
            }
        }

    > thead {
        > tr {
            > th, > td {
                border-right-width: rem(1px);
                border-right-style: solid;
                border-right-color: var(--table-border-color);
                border-bottom: rem(1px) solid var(--table-border-color);
                min-height: rem(48px);
                padding-top: rem(14px);
                padding-bottom: rem(13px);
                font-weight: $semibold;

                &:last-child {
                    border-right-width: 0;
                    }
                }
            }
        }

    > tbody {
        > tr {
            &:nth-child(2n) > th,
            &:nth-child(2n) > td {
                background-color: var(--table-alt-bg);
                }

            > th, > td {
                font-weight: $normal;
                min-height: rem(36px);
                padding-top: rem(8px);
                padding-bottom: rem(8px);
                background-image: linear-gradient(to bottom, var(--table-dotted-divider-color) 10%, rgba(255, 255, 255, 0) 0%);
                background-position: right;
                background-size: rem(1px) rem(6px);
                background-repeat: repeat-y;

                &:last-child { background-image: none; }
                }
            }
        }

    > tfoot {
        > tr {
            > th, > td {
                font-weight: normal;
                padding-top: rem(17px);
                padding-bottom: rem(18px);
                border-top: rem(1px) solid var(--table-border-color);

                > p,
                > .btn { margin-bottom: 0; }
                }
            }
        }

        
    //## '.table-bordered' - add border on the tables in case the tables will be added on a white background
    &.table-bordered {
        border: rem(1px) solid var(--table-border-color);
        border-bottom: 0;
        }


    //## '.table-hover' - hover state for table fields (this applied to .table-active too)
    &.table-active,
    &.table-hover {
        > thead {
            > tr {
                > th, > td {
                    &:hover {
                        background-color: var(--table-hover-bg);
                        border-right-color: var(--table-hover-border-color);
                        border-bottom-color: var(--table-hover-border-color);
                        box-shadow: -1px 0 0 var(--table-hover-border-color);
                        cursor: pointer;
                        }
                    }
                }
            }

        > tbody {
            > tr {
                &:hover > th,
                &:hover > td {
                    background-color: var(--table-hover-bg);
                    cursor: pointer;
                    }
                }
            }
        }


    //## '.table-active' - active state for table fields
    &.table-active {
        > thead {
            > tr {
                > th, > td {
                    &:active {
                        background-color: var(--table-active-bg);
                        border-right-color: var(--table-active-border-color);
                        border-bottom-color: var(--table-active-border-color);
                        box-shadow: rem(-1px) 0 0 var(--table-active-border-color);
                        }
                    }
                }
            }

        > tbody {
            > tr {
                &:active > th,
                &.active > th,
                &:active > td,
                &.active > td {
                    cursor: pointer;
                    background-color: var(--table-selected-bg);

                    &:first-child {
                        position: relative;

                        &:before {
                            content: '';
                            background: var(--table-selected-border-color);
                            width: rem(5px);
                            height: 100%;
                            display: inline-block;
                            position: absolute;
                            left: 0;
                            top: 0;
                            }
                        }
                    }

                    &.active:hover > th,
                    &.active:hover > td {
                        background-color: var(--table-hover-bg);
                    }
                }
            }
        }

    //## '.table-condensed' - make tables more compact
    &.table-condensed {
        > thead {
            > tr {
                > th, > td {
                    min-height: rem(30px);
                    padding-top: rem(6px);
                    padding-bottom: rem(6px);
                    }
                }
            }

        > tbody {
            > tr {
                > th, > td {
                    min-height: rem(27px);
                    padding-top: rem(4px);
                    padding-bottom: rem(4px);
                    }
                }
            }

        > tfoot {
            > tr {
                > th, > td {
                    padding-top: rem(13px);
                    padding-bottom: rem(13px);
                    }
                }
            }
        }
    }


//# Responsive tables
.table-responsive {
    min-height: .01%;
    overflow-x: auto;

    @media(max-width: $screen-md-min) {
        width: 100%;
        margin-bottom: rem(18px);
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        box-shadow: $shadow-10 var(--table-shadow-color);

        > .table { 
            margin: 0; 
            box-shadow: none;
            }
        }
    }
