// Imports
// @import '../settings/variables';

// Top navigation - items from the top nav with icons intead of text (right side of the nav)

.vismaicon.vismaicon-menu {
    &:before {
        background-color: var(--navigation-default-icon-bg);
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: cover;
        mask-size: cover;
    }

    // Standard
    &.vismaicon-message::before,
    &.vismaicon-chat::before {
        -webkit-mask-image: url("#{$icons-path}/worksurface/24_email.svg");
        mask-image: url("#{$icons-path}/worksurface/24_email.svg");
    }

    &.vismaicon-settings::before {
        -webkit-mask-image: url("#{$icons-path}/worksurface/24_settings.svg");
        mask-image: url("#{$icons-path}/worksurface/24_settings.svg");
    }

    &.vismaicon-user::before {
        -webkit-mask-image: url("#{$icons-path}/worksurface/24_user.svg");
        mask-image: url("#{$icons-path}/worksurface/24_user.svg");
    }

    &.vismaicon-shopping-cart::before {
        -webkit-mask-image: url("#{$icons-path}/worksurface/24_shopping_cart.svg");
        mask-image: url("#{$icons-path}/worksurface/24_shopping_cart.svg");
    }

    &.vismaicon-home::before {
        -webkit-mask-image: url("#{$icons-path}/worksurface/24_home.svg");
        mask-image: url("#{$icons-path}/worksurface/24_home.svg");
    }

    &.vismaicon-library::before {
        -webkit-mask-image: url("#{$icons-path}/worksurface/24_library.svg");
        mask-image: url("#{$icons-path}/worksurface/24_library.svg");
    }

    &.vismaicon-calendar::before {
        -webkit-mask-image: url("#{$icons-path}/worksurface/24_calendar.svg");
        mask-image: url("#{$icons-path}/worksurface/24_calendar.svg");
    }

    &.vismaicon-search::before {
        -webkit-mask-image: url("#{$icons-path}/worksurface/24_search.svg");
        mask-image: url("#{$icons-path}/worksurface/24_search.svg");
    }

    &.vismaicon-tools::before {
        -webkit-mask-image: url("#{$icons-path}/worksurface/24_tools.svg");
        mask-image: url("#{$icons-path}/worksurface/24_tools.svg");
    }

    &.vismaicon-user-settings::before {
        -webkit-mask-image: url("#{$icons-path}/worksurface/24_user_settings.svg");
        mask-image: url("#{$icons-path}/worksurface/24_user_settings.svg");
    }

    &.vismaicon-reminder::before {
        -webkit-mask-image: url("#{$icons-path}/worksurface/24_reminder.svg");
        mask-image: url("#{$icons-path}/worksurface/24_reminder.svg");
    }

    &.vismaicon-phone::before {
        -webkit-mask-image: url("#{$icons-path}/worksurface/24_phone.svg");
        mask-image: url("#{$icons-path}/worksurface/24_phone.svg");
    }

    &.vismaicon-logout::before {
        -webkit-mask-image: url("#{$icons-path}/worksurface/24_logout.svg");
        mask-image: url("#{$icons-path}/worksurface/24_logout.svg");
    }

    &.vismaicon-location::before {
        -webkit-mask-image: url("#{$icons-path}/worksurface/24_location.svg");
        mask-image: url("#{$icons-path}/worksurface/24_location.svg");
    }

    &.vismaicon-info::before {
        -webkit-mask-image: url("#{$icons-path}/worksurface/24_info.svg");
        mask-image: url("#{$icons-path}/worksurface/24_info.svg");
    }

    &.vismaicon-help::before {
        -webkit-mask-image: url("#{$icons-path}/worksurface/24_help.svg");
        mask-image: url("#{$icons-path}/worksurface/24_help.svg");
    }

    &.vismaicon-favourites::before {
        -webkit-mask-image: url("#{$icons-path}/worksurface/24_favourite.svg");
        mask-image: url("#{$icons-path}/worksurface/24_favourite.svg");
    }

    &.vismaicon-docs::before {
        -webkit-mask-image: url("#{$icons-path}/worksurface/24_document_production.svg");
        mask-image: url("#{$icons-path}/worksurface/24_document_production.svg");
    }

    // Dynamic
    &.vismaicon-dynamic {
        &.vismaicon-message::before,
        &.vismaicon-chat::before {
            -webkit-mask-image: url("#{$icons-path}/worksurface-dynamic/24_email.svg");
            mask-image: url("#{$icons-path}/worksurface-dynamic/24_email.svg");
        }

        &.vismaicon-settings::before {
            -webkit-mask-image: url("#{$icons-path}/worksurface-dynamic/24_settings.svg");
            mask-image: url("#{$icons-path}/worksurface-dynamic/24_settings.svg");
        }

        &.vismaicon-user::before {
            -webkit-mask-image: url("#{$icons-path}/worksurface-dynamic/24_user.svg");
            mask-image: url("#{$icons-path}/worksurface-dynamic/24_user.svg");
        }

        &.vismaicon-shopping-cart::before {
            -webkit-mask-image: url("#{$icons-path}/worksurface-dynamic/24_shopping_cart.svg");
            mask-image: url("#{$icons-path}/worksurface-dynamic/24_shopping_cart.svg");
        }

        &.vismaicon-home::before {
            -webkit-mask-image: url("#{$icons-path}/worksurface-dynamic/24_home.svg");
            mask-image: url("#{$icons-path}/worksurface-dynamic/24_home.svg");
        }

        &.vismaicon-library::before {
            -webkit-mask-image: url("#{$icons-path}/worksurface-dynamic/24_library.svg");
            mask-image: url("#{$icons-path}/worksurface-dynamic/24_library.svg");
        }

        &.vismaicon-calendar::before {
            -webkit-mask-image: url("#{$icons-path}/worksurface-dynamic/24_calendar.svg");
            mask-image: url("#{$icons-path}/worksurface-dynamic/24_calendar.svg");
        }

        &.vismaicon-search::before {
            -webkit-mask-image: url("#{$icons-path}/worksurface-dynamic/24_search.svg");
            mask-image: url("#{$icons-path}/worksurface-dynamic/24_search.svg");
        }

        &.vismaicon-tools::before {
            -webkit-mask-image: url("#{$icons-path}/worksurface-dynamic/24_tools.svg");
            mask-image: url("#{$icons-path}/worksurface-dynamic/24_tools.svg");
        }

        &.vismaicon-user-settings::before {
            -webkit-mask-image: url("#{$icons-path}/worksurface-dynamic/24_user_settings.svg");
            mask-image: url("#{$icons-path}/worksurface-dynamic/24_user_settings.svg");
        }

        &.vismaicon-reminder::before {
            -webkit-mask-image: url("#{$icons-path}/worksurface-dynamic/24_reminder.svg");
            mask-image: url("#{$icons-path}/worksurface-dynamic/24_reminder.svg");
        }

        &.vismaicon-phone::before {
            -webkit-mask-image: url("#{$icons-path}/worksurface-dynamic/24_phone.svg");
            mask-image: url("#{$icons-path}/worksurface-dynamic/24_phone.svg");
        }

        &.vismaicon-logout::before {
            -webkit-mask-image: url("#{$icons-path}/worksurface-dynamic/24_logout.svg");
            mask-image: url("#{$icons-path}/worksurface-dynamic/24_logout.svg");
        }

        &.vismaicon-location::before {
            -webkit-mask-image: url("#{$icons-path}/worksurface-dynamic/24_location.svg");
            mask-image: url("#{$icons-path}/worksurface-dynamic/24_location.svg");
        }

        &.vismaicon-info::before {
            -webkit-mask-image: url("#{$icons-path}/worksurface-dynamic/24_info.svg");
            mask-image: url("#{$icons-path}/worksurface-dynamic/24_info.svg");
        }

        &.vismaicon-help::before {
            -webkit-mask-image: url("#{$icons-path}/worksurface-dynamic/24_help.svg");
            mask-image: url("#{$icons-path}/worksurface-dynamic/24_help.svg");
        }

        &.vismaicon-favourites::before {
            -webkit-mask-image: url("#{$icons-path}/worksurface-dynamic/24_favourite.svg");
            mask-image: url("#{$icons-path}/worksurface-dynamic/24_favourite.svg");
        }

        &.vismaicon-docs::before {
            -webkit-mask-image: url("#{$icons-path}/worksurface-dynamic/24_document_production.svg");
            mask-image: url("#{$icons-path}/worksurface-dynamic/24_document_production.svg");
        }
    }
}

.navbar-secondary {
    .vismaicon {
        &::before { background-color: var(--navigation-secondary-icon-bg) }

        // &.vismaicon-alert::before { background-position: 0 0; }
    }
}
