//== Carets

//=== Variables
$caret-large: $arrow-icon-large;
$caret-medium: $arrow-icon-large; // this is deprecated - will be removed in time, until then is replaced with arrow-icon-large
$caret-small: $arrow-icon-small;


//=== Mixins
@mixin caret-large() {
  height: rem(6px);
  width: rem(10px);
  -webkit-mask-position: 0 0;
  mask-position: 0 0;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  mask-size: cover;
}

@mixin caret-medium() { // this is deprecated - will be removed in time, until then is replaced with caret-large
  @include caret-large();
}

@mixin caret-small() {
  height: rem(5px);
  width: rem(8px);
  -webkit-mask-position: 0 0;
  mask-position: 0 0;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  mask-size: cover;
}


// Large
//blue
@mixin caret-large-primary() {
  @include caret-large();
  -webkit-mask-image: $caret-large;
  mask-image: $caret-large;
  background-color: var(--caret-primary-bg);
}

//white
@mixin caret-large-secondary() {
  @include caret-large();
  -webkit-mask-image: $caret-large;
  mask-image: $caret-large;
  background-color: var(--caret-secondary-bg);
}

//black
@mixin caret-large-default() {
  @include caret-large();
  -webkit-mask-image: $caret-large;
  mask-image: $caret-large;
  background-color: var(--caret-default-bg);
} 

//grey
@mixin caret-large-disabled() {
  @include caret-large();
  -webkit-mask-image: $caret-large;
  mask-image: $caret-large;
  background-color: var(--caret-disabled-bg);
}


// Medium --- this is deprecated - will be removed in time
@mixin caret-medium-primary() {
  @include caret-large();
  -webkit-mask-image: $caret-large;
  mask-image: $caret-large;
  background-color: var(--caret-primary-bg);
}

@mixin caret-medium-secondary() {
  @include caret-large();
  -webkit-mask-image: $caret-large;
  mask-image: $caret-large;
  background-color: var(--caret-secondary-bg);
}

@mixin caret-medium-default() {
  @include caret-large();
  -webkit-mask-image: $caret-large;
  mask-image: $caret-large;
  background-color: var(--caret-default-bg);
}

@mixin caret-medium-disabled() {
  @include caret-large();
  -webkit-mask-image: $caret-large;
  mask-image: $caret-large;
  background-color: var(--caret-disabled-bg);
}


// Small
@mixin caret-small-primary() {
  -webkit-mask-image: $caret-small;
  mask-image: $caret-small;
  @include caret-small();
  background-color: var(--caret-primary-bg);
}

@mixin caret-small-secondary() {
  @include caret-small();
  -webkit-mask-image: $caret-small;
  mask-image: $caret-small;
  background-color: var(--caret-secondary-bg);
}

@mixin caret-small-default() {
  @include caret-small();
  -webkit-mask-image: $caret-small;
  mask-image: $caret-small;
  background-color: var(--caret-default-bg);
}

@mixin caret-small-disabled() {
  @include caret-small();
  -webkit-mask-image: $caret-small;
  mask-image: $caret-small;
  background-color: var(--caret-disabled-bg);
  }

