
// local variable
// only the light version
$drag-drop-path: $image-path + '/drag-drop' !default;

$drag-drop-border-mask-normal: "#{$drag-drop-path}/drag-drop.svg";
$drag-drop-border-mask-focus: "#{$drag-drop-path}/drag-drop-focus.svg";
$drag-drop-border-mask-disabled: "#{$drag-drop-path}/drag-drop-disabled.svg";

$drag-drop-border-mask-sm-normal: "#{$drag-drop-path}/drag-drop-sm.svg";
$drag-drop-border-mask-sm-focus: "#{$drag-drop-path}/drag-drop-sm-focus.svg";
$drag-drop-border-mask-sm-disabled: "#{$drag-drop-path}/drag-drop-sm-disabled.svg";

$drag-drop-icon: "#{$drag-drop-path}/drag-drop-icon.svg";

$drag-drop-sm-icon: "#{$drag-drop-path}/drag-drop-sm-icon.svg";

$preview-item-close-icon: "#{$drag-drop-path}/drag-drop-sm-icon.svg";


.drop-area {
    display: flex;
    flex-direction: column;
    position: relative;
    content: "";
    margin: 0 auto rem(24px);
    padding: rem(144px) rem(24px) rem(24px);

    text-align: center;
    border-radius: rem(32px);
    background-color: transparent;
    z-index: 1;

    &.drop-area-sm {
        padding: rem(48px) 0 0;
        border-radius: rem(16px);

        .drop-area-upload {
            &::before {
                border: {
                    radius: rem(14px);
                    width: rem(21px);
                }
                border-image: url($drag-drop-border-mask-sm-normal) 21 space;
            }

            &::after {
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                padding: rem(8px);

                mask-image: url($drag-drop-sm-icon);
                mask-position: center center;
            }

            &:not(.btn) {
                &:focus {
                    &::before {
                        border-image: url($drag-drop-border-mask-sm-focus) 21 stretch;
                    }
                }
            }
        }

        .drop-area-input {
            &:disabled, &.disabled {
                + .drop-area-upload {
                    &:not(.btn) {
                        &::before {
                            border-image: url($drag-drop-border-mask-sm-disabled) 21 space;
                        }
                    }
                }
            }
        }

        .spinner {
            top: calc(#{'50% - ' rem(24px)});
        }
    }

    &.highlight {
        .drop-area-upload {
            &::before {
                background-color: var(--drop-area-highlight-bg);
            }
        }
    }

    .drop-area-input {
        display: none;

        &:disabled, &.disabled {
            + .drop-area-upload {
                pointer-events: none;
                cursor: default;
    
                &::after {
                    pointer-events: none;
                    background-color: var(--button-disabled-text-color);
                }
    
                &:not(.btn) {
                    &::before {
                        pointer-events: none;
                        border-image: url($drag-drop-border-mask-disabled) 37 space;
                    }
                }
            }
        }
    }

    .drop-area-upload {
        &::before, &::after {
            display: block;
            position: absolute;
            cursor: pointer;
            content: '';

            background-color: transparent;
        }

        &::before {
            cursor: pointer;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;

            border: {
                radius: rem(30px);
                style: solid;
                width: rem(37px);
                color: var(--drop-area-border-color);
                image: url($drag-drop-border-mask-normal) 37 space;
            }
            z-index: -1;
        }

        &::after {
            width: rem(64px);
            height: rem(64px);
            top: rem(48px);
            left: calc(#{'50% - ' rem(32px)});
            padding: rem(12px);

            background-color: var(--icon-bg);
            mask: {
                image: url($drag-drop-icon);
                size: auto;
                repeat: no-repeat;
                position: 0 0;
            }
        }

        &:focus {
            outline: none;
        }

        &:not(.btn) {
            display: block;
            position: absolute;
            cursor: pointer;
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: transparent;

            &:focus {
                &::before {
                    border: {
                        image: url($drag-drop-border-mask-focus) 37 stretch;
                    }
                }
            }
        }

        &.btn {
            margin: 0 auto rem(8px);

            &::before, &::after {
                pointer-events: none;
            }
        }
    }

    .spinner {
        display: none;
        position: absolute;
        pointer-events: none;
        top: calc(#{'50% - ' rem(44px)});
        left: calc(#{'50% - ' rem(24px)});

        &.show {
            display: block;
        }
    }

    p {
        &, &:last-child {
            display: inline-block;
            margin: 0 auto rem(8px);
        }
    }

    *:not(.drop-area-upload) {
        z-index: 1;
    }

    .drop-area-preview {
        max-width: fit-content;
        margin-top: rem(40px);
        margin-bottom: 0;
        text-align: left;

        &.drop-area-preview-list {
            max-width: auto;
            max-width: unset;
        }
    }

    :disabled &, .disabled &,
    &:disabled, &.disabled {
        .drop-area-upload {
            pointer-events: none;
            cursor: default;

            &::after {
                pointer-events: none;
                background-color: var(--button-disabled-text-color);
            }

            &:not(.btn) {
                &::before {
                    pointer-events: none;
                    border-image: url($drag-drop-border-mask-disabled) 37 space;
                }
            }
        }

        &.drop-area-sm {
            .drop-area-upload {
                &:not(.btn) {
                    &::before {
                        border-image: url($drag-drop-border-mask-sm-disabled) 21 space;
                    }
                }
            }
        }

        .drop-area-preview {
            a, button {
                pointer-events: none;
                cursor: default;
                color: var(--button-disabled-text-color);
            }

            .preview-item, .preview-item-img {
                box-shadow: none;

               .btn-close-icon {
                    display: none;
               }
            }
        }
    }
}

.drop-area-preview {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: rem(16px);
    position: relative;
    margin-bottom: rem(24px);

    &.drop-area-preview-list {
        flex-direction: column;
        gap: unset;
        padding: rem(24px);

        border-radius: rem(8px);
        background-color: var(--module-container-bg-color);
        box-shadow: var(--module-container-shadow);

        .preview-item {
            display: block;
            height: rem(40px);
            margin: 0 0 rem(16px);
            padding: rem(2px) rem(32px) 0 rem(2px);
            border-radius: 0;
            border-bottom: rem(1px) dashed #C1C3C5;
            background-color: transparent;
            box-shadow: none;

            &:last-child {
                height: rem(24px);
                margin: 0;
                border: 0;
            }

            .btn-link {
                top: 0;
                right: 0;
            }
        }
    }

    &.drop-area-preview-alt {
        &.drop-area-preview-list {
            background-color: var(--drop-area-preview-item-bg);
            box-shadow: none;
        }

        .preview-item, .preview-item-img {
            background-color: var(--drop-area-preview-item-bg);
            box-shadow: none;
        }
    }

    .preview-item {
        display: inline-block;
        // overflow: hidden;
        position: relative;
        height: rem(48px);
        padding: rem(16px) rem(48px) rem(16px) rem(16px);

        white-space: nowrap;
        text-overflow: ellipsis;
        border-radius: rem(8px);
        background-color: var(--module-container-bg-color);
        box-shadow: var(--module-container-shadow);

        .btn-link {
            position: absolute;
            top: calc(#{'50% - ' rem(12px)});
            right: rem(12px);
            cursor: pointer;
            padding: rem(2px);
            outline: 0;
            border: rem(2px) solid transparent;
            background-color: transparent;
            box-shadow: none;

            &:focus {
                border-color: var(--drop-area-focus-border-color);
            }

            .vismaicon {
                display: block;
                top: 0;
            }
        }

        a .vismaicon {
            &::before { top: rem(1px); }
        }
    }

    .preview-item-img {
        display: inline-block;
        box-sizing: border-box;
        position: relative;
        vertical-align: middle;
        width: rem(128px);
        height: rem(128px);
        margin: rem(8px) rem(8px) 0 0;
        padding: rem(16px);
        border-radius: rem(16px);
        border: 0;
        background-color: var(--module-container-bg-color);
        box-shadow: var(--module-container-shadow);

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-color: transparent;
            opacity: var(--drop-area-preview-item-op);
        }

        .btn-link {
            position: absolute;
            cursor: pointer;
            display: flex;
            width: rem(24px);
            height: rem(24px);
            top: rem(-12px);
            right: rem(-12px);
            margin: 0;
            padding: 0;
            text-align: center;
            border-radius: rem(8px);
            border: rem(2px) solid transparent;
            outline: 0;
            background-color: var(--drop-area-preview-item-border-color);
            box-shadow: 0 rem(5px) rem(10px) rgba(0, 135, 224, 0.15);

            &.btn-close-icon {
                background-color: var(--icon-bg);
                background-repeat: no-repeat;
                background-position: center center;
                background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25 24.9414L15 14.9414' stroke='%23ffffff' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M15 24.9414L25 14.9414' stroke='%23ffffff' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
            }

            &:focus {
                border-color: var(--drop-area-focus-border-color);
            }
        }
    }
}