
// Button Primary Theme 
.btn {
    &.btn-primary {
        color: var(--button-primary-text-color);

        border-color: var(--button-primary-border-color);
        background-color: var(--button-primary-bg);
        box-shadow: $shadow-30  var(--button-primary-shadow-color);

        //### '.btn-primary' hover state
        &:hover {
            border-color: var(--button-primary-hover-border-color);
            background-color: var(--button-primary-hover-bg);
        }

        //### '.btn-primary' focus state
        &:focus {
            border-color: var(--button-primary-focus-border-color);
            box-shadow: inset 0 0 0 rem(1px) var(--button-primary-focus-border-color), inset 0 0 0 rem(2px) var(--button-primary-focus-inner-shadow-color), $shadow-20 var(--button-primary-focus-shadow-color);
        }

        //### '.btn-primary' active/pressed and active/selected state
        &:active, &.active {
            border-color: var(--button-primary-active-border-color);
            background-color: var(--button-primary-active-bg);
            box-shadow: none;
        }

        //### '.btn-primary' disabled state
        &:disabled, &.disabled,
        :disabled &, .disabled & {
            color: var(--button-disabled-text-color);

            border-color: var(--button-disabled-border-color);
            background-color: var(--button-disabled-bg);
            box-shadow: none;
        }
    }
}
