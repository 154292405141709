//=== primary style (blue navigation)

.vertical-nav {
    &.vertical-nav-blue,
    &.vertical-nav-primary {
        background-color: var(--vertical-nav-primary-bg);
        box-shadow: var(--vertical-nav-primary-shadow);

        &.hidden-menu {
            border-right: rem(5px) solid var(--vertical-nav-primary-bg);

            ul li.show-nav {
                > a {
                    background-color: var(--vertical-nav-primary-bg); 

                    &:after {
                        background-color: var(--vertical-nav-primary-item-text);
                    }
                }

                &.show-nav-primary {
                    > a {
                        background-color: var(--vertical-nav-primary-bg);

                        &:focus {
                            outline: rem(2px) solid $white;
                        }
                    }
                }
            }
        }

        ul li {
            a {
                color: var(--vertical-nav-primary-item-text);

                &:hover { background-color: var(--vertical-nav-primary-item-hover-bg); }

                &:focus { outline-color: $white; }

                &:after { background-color: var(--vertical-nav-primary-item-text); }
            }

            &.active {
                > a:only-child { background-color: var(--vertical-nav-primary-item-active-bg); }
            }

            &.active,
            &.active.active-child {
                > a {
                    background-color: var(--vertical-nav-primary-collapse-bg);

                    //&:hover { background-color: #24618a; }
                }
            }

            &.disabled {
                > a { color: var(--vertical-nav-primary-item-disabled); }
            }

            &.show-nav {
                > a {
                    background-color: var(--vertical-nav-primary-collapse-btn-bg);

                    &:after {
                        background-color: var(--vertical-nav-primary-collapse-arrow);
                    }
                }

                &.show-nav-primary {
                    > a {
                        background-color: var(--vertical-nav-primary-collapse-btn-bg);
                        box-shadow: var(--vertical-nav-primary-collapse-btn-shadow);

                        &:focus {
                            outline: rem(2px) solid $white;
                        }
                    }
                }
            }
        }

        > ul {
            > li {
                > ul { background: var(--vertical-nav-primary-collapse-bg); }
            }
        }
    }
}