
// Combine sets of button groups into button toolbars for more complex components
.btn-toolbar {
    //# Alternative styling for group buttons
    &.btn-toolbar-primary {
        .btn-group {

            //## Defind the states for selected or pressed button in the alternative styling
            .btn {

                //### Selected state
                &.active {
                    color: var(--button-primary-text-color);

                    border-color: var(--button-primary-selected-border-color);
                    background-color: var(--button-primary-bg);

                    // selected and hovered state
                    &:hover {
                        border-color: var(--button-primary-hover-border-color);
                        background-color: var(--button-primary-hover-bg);

                        // button style for a button that precede a 'selected and hoverd' button
                        + .btn {
                            box-shadow: rem(-1px) 0 0 0 var(--button-primary-hover-border-color), $shadow-20 var(--button-shadow-color);

                            &.active {  box-shadow: rem(-1px) 0 0 0 var(--button-primary-hover-border-color); }
                        }
                    } // END '.btn-toolbar.btn-toolbar-primary .btn-group .btn.active:hover'

                    // selected and focused state
                    &:focus {
                        border-color: var(--button-primary-focus-border-color);
                        box-shadow: inset 0 0 0 rem(1px) var(--button-primary-focus-border-color), inset 0 0 0 rem(2px) var(--button-primary-focus-inner-shadow-color);

                        // button style for a button that precede a 'selected and focused' button
                        + .btn {
                            box-shadow: rem(-1px) 0 0 0 var(--button-primary-focus-border-color), $shadow-20 var(--button-shadow-color);

                            &.active {
                                box-shadow: rem(-1px) 0 0 0 var(--button-primary-focus-border-color);

                                &:hover { box-shadow: rem(-1px) 0 0 0 var(--button-primary-focus-border-color); }
                            }

                            &:hover { box-shadow: rem(-1px) 0 0 0 var(--button-primary-focus-border-color), $shadow-20 var(--button-shadow-color); }
                        }
                    } // END '.btn-toolbar.btn-toolbar-primary .btn-group .btn.active:focus'

                    // selected and pressed state
                    &:active {
                        border-color: var(--button-primary-active-border-color);
                        background-color: var(--button-primary-active-bg);
                        box-shadow: none;

                        // button style for a button that precede a 'selected and pressed' button
                        + .btn {
                            box-shadow: rem(-1px) 0 0 0 var(--button-primary-inner-border-color), rem(-6px) 0 rem(4px) rem(-4px) var(--button-shadow-color), $shadow-20 var(--button-shadow-color);

                            &.active {
                                &, &:hover { box-shadow: rem(-1px) 0 0 0 var(--button-primary-inner-border-color), rem(-6px) 0 rem(4px) rem(-4px) var(--button-shadow-color); }
                            }

                            &:hover { box-shadow: rem(-1px) 0 0 0 var(--button-primary-inner-border-color), $shadow-20 var(--button-focus-shadow-color); }
                        }
                    } // END '.btn-toolbar.btn-toolbar-primary .btn-group .btn.active:active'

                    // selected and disabled state
                    &:disabled, &.disabled {
                        color: var(--button-disabled-text-color);

                        border-color: var(--button-disabled-border-color);
                        background-color: var(--button-disabled-bg);

                        // button style for a button that precede a 'selected and disabled' button
                        + .btn { box-shadow: rem(-1px) 0 0 0 var(--button-border-color), rem(6px) 0 rem(4px) rem(-4px) var(--button-shadow-color), $shadow-20 var(--button-shadow-color); }
                    } // END '.btn-toolbar.btn-toolbar-primary .btn-group .btn.active.disabled'

                    // button style for a button that precede a 'selected' button
                    + .btn {
                        box-shadow: rem(-1px) 0 0 0 var(--button-primary-selected-border-color), rem(-6px) 0 rem(4px) rem(-4px) var(--button-shadow-color), $shadow-20 var(--button-shadow-color);

                        &:hover { box-shadow: rem(-1px) 0 0 0 var(--button-hover-border-color), $shadow-20 var(--button-hover-shadow-color); }

                        &:focus { box-shadow: rem(-1px) 0 0 0 var(--button-focus-border-color), inset 0 0 0 rem(1px) var(--button-focus-border-color), $shadow-20 var(--button-focus-shadow-color); }

                        &:active { box-shadow: rem(-1px) 0 0 0 var(--button-border-color), inset rem(6px) 0 rem(4px) rem(-4px) var(--button-shadow-color); }
                    } // END '.btn-toolbar.btn-toolbar-primary .btn-group .btn.active + .btn'
                } // END '.btn-toolbar.btn-toolbar-primary .btn-group .btn.active'

                //### Button hoverd state
                &:hover {
                    // button style for a button that precede a 'hoverd' button
                    + .btn {
                        &.active {
                            &:focus { box-shadow: rem(-1px) 0 0 0 var(--button-focus-border-color), inset 0 0 0 rem(1px) var(--button-focus-border-color), inset 0 0 0 rem(2px) var(--button-primary-focus-inner-shadow-color); }

                            &:active { box-shadow: rem(-1px) 0 0 0 var(--button-primary-inner-border-color), inset rem(6px) 0 rem(4px) rem(-4px) var(--button-shadow-color); }

                            &, &:disabled, &.disabled { box-shadow: rem(-1px) 0 0 0 var(--button-hover-border-color), inset rem(6px) 0 rem(4px) rem(-4px) var(--button-shadow-color); }
                        }
                    }
                } // END '.btn-toolbar.btn-toolbar-primary .btn-group .btn:hover'

                //### Button focus state
                &:focus {
                    // button style for a button that precede a 'focused' button
                    + .btn {
                        &.active {
                            &:hover { box-shadow: rem(-1px) 0 0 0 var(--button-focus-border-color); }

                            &, &:disabled, &.disabled { box-shadow: rem(-1px) 0 0 0 var(--button-focus-border-color), inset rem(6px) 0 rem(4px) rem(-4px) var(--button-shadow-color); }
                        }
                    }
                } // END '.btn-toolbar.btn-toolbar-primary .btn-group .btn:focus'

                //### Button pressed state
                &:active {
                    // button style for a button that precede a 'pressed' button
                    + .btn {
                        &.active {
                            box-shadow: rem(-1px) 0 0 0 var(--button-border-color), rem(6px) 0 rem(4px) rem(-4px) var(--button-shadow-color);

                            &:hover { box-shadow: rem(-1px) 0 0 0 var(--button-border-color), rem(-6px) 0 rem(4px) rem(-4px) var(--button-shadow-color); }

                            &:disabled, &.disabled { box-shadow: rem(-6px) 0 rem(4px) rem(-4px) var(--button-shadow-color), rem(-2px) 0 0 rem(-1px) $neutral-40; }
                        }
                    }
                } // END '.btn-toolbar.btn-toolbar-primary .btn-group .btn:active'


                //### Restyling the second and up button in the group
                + .btn {
                    &.active {
                        box-shadow: rem(-1px) 0 0 0 var(--button-primary-selected-border-color), inset rem(6px) 0 rem(4px) rem(-4px) var(--button-shadow-color);

                        &:hover { box-shadow: rem(-1px) 0 0 0 var(--button-primary-inner-border-color); }

                        &:focus { box-shadow: rem(-1px) 0 0 0 var(--button-focus-border-color), inset 0 0 0 rem(1px) var(--button-focus-border-color), inset 0 0 0 rem(2px) var(--button-primary-focus-inner-shadow-color); }

                        &:active { box-shadow: rem(-1px) 0 0 0 var(--button-primary-inner-border-color), inset rem(6px) 0 rem(4px) rem(-4px) var(--button-shadow-color); }

                        &:disabled, &.disabled { box-shadow: rem(-1px) 0 0 0 var(--button-border-color), inset rem(6px) 0 rem(4px) rem(-4px) var(--button-shadow-color); }
                    } // END '.btn-toolbar.btn-toolbar-primary .btn-group .btn + .btn.active'
                } // END '.btn-toolbar.btn-toolbar-primary .btn-group .btn + .btn'
            } // END '.btn-toolbar.btn-toolbar-primary .btn-group .btn'
        } // END '.btn-toolbar.btn-toolbar-primary .btn-group'
    } // END '.btn-toolbar.btn-toolbar-primary'
} // END '.btn-toolbar'


// The default 'Disabled state' for the button in alternative styling group
:disabled .btn-toolbar-primary .btn-group .btn,
.disabled .btn-toolbar-primary .btn-group .btn,
.btn-toolbar-primary:disabled .btn-group .btn,
.btn-toolbar-primary.disabled .btn-group .btn,
.btn-toolbar-primary .btn-group:disabled .btn,
.btn-toolbar-primary .btn-group.disabled .btn {
    //# Selected state for a button in a disabled group
    &.active {
        color: var(--button-disabled-text-color);

        border-color: var(--button-disabled-border-color);
        background-color: var(--button-disabled-bg);

        //### Removing 'focus state' for a 'selected' button in a disabled group
        &:focus {
            border-color: var(--button-disabled-border-color);
            box-shadow: none;

            + .btn {
                box-shadow: rem(-1px) 0 0 0 var(--button-inner-border-color), rem(-6px) 0 rem(4px) rem(-4px) var(--button-shadow-color);

                &.active { box-shadow: rem(-1px) 0 0 0 var(--button-disabled-border-color); }
            }
        } // END '.btn-toolbar.btn-toolbar-primary.disabled .btn-group .btn.active:focus'

        + .btn {
            &, &:focus {
                box-shadow: rem(-1px) 0 0 0 var(--button-disabled-border-color), rem(-6px) 0 rem(4px) rem(-4px) var(--button-shadow-color);

                &.active { box-shadow: rem(-1px) 0 0 0 var(--button-disabled-border-color); }
            }
        } // END '.btn-toolbar.btn-toolbar-primary.disabled .btn-group .btn.active + .btn'
    } // END '.btn-toolbar.btn-toolbar-primary.disabled .btn-group .btn.active'

    //# Removing 'focus state' for a button in a disabled group
    &:focus {
        + .btn {
            &.active { box-shadow: var(--button-inner-border-color), inset rem(6px) 0 rem(4px) rem(-4px) var(--button-shadow-color); }
        }
    } // END '.btn-toolbar.btn-toolbar-primary.disabled .btn-group .btn:focus'

    //## Second and up button for a button in 'disabled group'
    + .btn {
        &.active {
            &, &:focus { box-shadow: rem(-1px) 0 0 0 var(--button-disabled-border-color), inset rem(6px) 0 rem(4px) rem(-4px) var(--button-shadow-color); }
        }
    } // END '.btn-toolbar.btn-toolbar-primary.disabled .btn-group .btn + .btn'
} // END '.btn-toolbar.btn-toolbar-primary.disabled .btn-group .btn' or '.btn-toolbar.btn-toolbar-primary .btn-group.disabled .btn'
