
// Button Default Theme
.btn {
    border-color: var(--button-border-color);
    background-color: var(--button-bg);
    box-shadow: $shadow-20 var(--button-shadow-color);

    //### '.btn-default' hover state
    &:hover {
        border-color: var(--button-hover-border-color);
        background-color: var(--button-hover-bg);
        box-shadow: $shadow-20 var(--button-hover-shadow-color);
    }

    //### '.btn-default' focus state
    &:focus {
        border-color: var(--button-focus-border-color);
        box-shadow: inset 0 0 0 rem(1px) var(--button-focus-border-color), $shadow-20 var(--button-focus-shadow-color);
    }

    //### '.btn-default' active/pressed and active/selected state
    &:active, &.active {
        border-color: var(--button-active-border-color);
        background-color: var(--button-active-bg);
        box-shadow: none;
    }

    //### '.btn-default' active/selected state in group buttons
    .btn-group & {
        &.active {
            background-color: var(--button-selected-bg);

            &:active { background-color: var(--button-active-bg); }
        }
    }

    //### '.btn-default' disabled state
    &:disabled, &.disabled,
    :disabled &, .disabled & {
        color: var(--button-disabled-text-color);

        border-color: var(--button-disabled-border-color);
        background-color: var(--button-disabled-bg);
        box-shadow: none;
    }
}
