
// Container widths - Set the container width
@if $enable-grid-classes {
    .container {
        @include make-container();
        @include make-container-max-widths(); 
    }
  }
  
// Fluid container
@if $enable-grid-classes {
    .container-fluid {
        @include make-container();
        padding-left: $grid-gutter-width;
        padding-right: $grid-gutter-width;

        @media(max-width: $screen-sm-min){ 
            .row { 
                margin-left: 0;
                margin-right: 0;
            }  
        }
    }
}
  
// Row - Rows contain and clear the floats of your columns.
@if $enable-grid-classes {
    .row {
        @include make-row();
    }

    // Remove the negative margin from default .row, then the horizontal padding from all immediate children columns (to prevent runaway style inheritance).
    .no-gutters {
        margin-right: 0;
        margin-left: 0;

        > .col,
        > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
        }
    }
}

// Columns - Common styles for small and large grid columns
@if $enable-grid-classes {
    @include make-grid-columns(); 
} 