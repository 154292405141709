
/*
 * Login page - secondary
 */

.login-page-secondary {
    background-color: var(--module-container-bg-color);
    padding: rem(32px) calc(#{'50% + ' rem(32px)}) 0 rem(32px);
    position: relative;
    z-index: 1; 

    @media (max-width: $screen-lg-min) {
        padding: rem(24px) calc(#{'50% + ' rem(24px)}) 0 rem(24px);
    }

    @media (max-width: $screen-md-min) {
        padding: rem(48px) 0 rem(60px);
    }

    &:before, 
    &:after {
        content:'';
        width: calc(#{'50% - ' rem(32px)});
        height: calc(#{'100% - ' rem(64px)});
        display: block;
        position: absolute;
        right: rem(32px);
        top: rem(32px);
        border-radius: $border-radius-20;
        z-index: -1;

        @media (max-width: $screen-lg-min) {
            width: calc(#{'50% - ' rem(24px)});
            height: calc(#{'100% - ' rem(48px)});
            right: rem(24px);
            top: rem(24px);
        }

        @media (max-width: $screen-md-min) {
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            border-radius: 0;
        }
    }

    &:before {
        left: auto;
        right: rem(32px);

        background: {
            repeat: no-repeat;
            color: transparent;
            size: auto 120%; 
        }

        @media (max-width: $screen-md-min) { right: auto; }
    }

    &.login-page-secondary-bg-1:before {
        background: {
            image: url('#{$image-path}/login/login-background-secondary-1.jpg');
            position: left center;
        }
    }
    &.login-page-secondary-bg-2:before {
        background: {
            image: url('#{$image-path}/login/login-background-secondary-2.jpg');
            position: left center;
        }
    }
    &.login-page-secondary-bg-3:before {
        background: {
            image: url('#{$image-path}/login/login-background-secondary-3.jpg');
            position: right center;
        }
    }
    &.login-page-secondary-bg-4:before {
        background: {
            image: url('#{$image-path}/login/login-background-secondary-4.jpg');
            position: left center;
            size: cover;
        }
    }
    &.login-page-secondary-bg-5:before {
        background: {
            image: url('#{$image-path}/login/login-background-secondary-5.jpg');
            position: right center;
            size: auto 100%;
        }
    }
      
    &:after {
        background: var(--mask-bg);
        opacity: 0.4;
    }

    .login-box {
        @media (max-width: $screen-xl-min) and (min-width: $screen-lg-min) { 
            padding: rem(25px) rem(112px) rem(25px);
        }

        @media (min-width: $screen-md-min) { 
            background: transparent;
            box-shadow: none;
        }

        @media (max-width: $screen-lg-max) and (min-width: $screen-md-min) {
            width: 100%;
            padding: rem(25px) rem(80px) rem(25px);
        }

        .login-header {
            img {
                @media (max-width: $screen-xl-min) { 
                    max-width: rem(72px);
                }

                @media (max-width: $screen-lg-min) { 
                    max-width: rem(64px);
                    margin-bottom: rem(5px);
                }
            }

            h1 {
                @media (max-width: $screen-xl-min) {
                    font-size: rem(26px);
                    margin-bottom: rem(24px);
                }

                @media (max-width: $screen-lg-min) {
                    margin-bottom: rem(16px);
                }
            }
        }
    }

    .login-footer {
        width: 100%;
        margin-bottom: rem(30px);
        margin-top: rem(20px);

        @media (max-width: $screen-xl-min) {
            margin-bottom: rem(10px);
        }

        @media (max-width: $screen-md-min) {
            bottom: rem(70px);
            position: absolute;
        }
    }

    .cookies-alert {
        //max-width: 80%;
        border-radius: $border-radius-20;
        margin: 0 auto;
        padding: rem(25px) rem(32px) rem(25px) rem(48px);
        justify-content: space-between;

        @media (min-width: $screen-md-min) {
            background: var(--cookies-alert-secondary-bg);
            width: calc(#{'50% - ' rem(64px)});
            left: rem(32px);
            top: rem(32px);
        }

        @media (max-width: $screen-md-min) {
            padding: rem(15px) rem(30px) rem(56px);
            justify-content: center;
            border-radius: 0;
            top: 0;
            left: 0;
        }

        p { 
            padding-right: rem(80px);

            @media (max-width: $screen-xl-min) { padding-right: rem(16px); }
            @media (max-width: $screen-md-min) { padding-right: 0; }
        }

        .vismaicon-info {
            margin-right: rem(24px);
        }

        a:not(.btn) {
            padding: 0;
            display: block;
        }
    } 

    .login-footer-alert {
        justify-content: center;

        @media (min-width: $screen-md-min) {
            background: transparent;
        }

        @media (max-width: $screen-md-min) {
            bottom: rem(-60px);
        }

        > ul > li .dropdown-menu {
            bottom: calc(#{'100% - ' rem(-20px)});
        }
    }
}