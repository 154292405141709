
// Top navigation - primary styles - GRAPHITE VERSION

.navbar {
    &.navbar-primary {
        background: var(--navigation-primary-bg);

        .dropdown {
            > .dropdown-toggle {
                .caret {
                    background-color: var(--caret-navigation-bg);
                }

                &:hover {
                    background-color: var(--navigation-primary-item-hover-bg);
                }
            }

            &.open {
                > .dropdown-toggle {
                    .caret {
                        background-color: var(--caret-default-bg);
                    }
                }
            }
        }
    }
}

/* Product dropdown area */
.navbar-primary {
    .navbar-header {
        .navbar-brand {
            > a,
            > .dropdown-toggle {
                color: var(--navigation-default-item-text-color);

                &:hover { background-color: var(--navigation-primary-item-hover-bg); }

                &:focus {
                    outline: rem(2px) solid var(--navigation-default-item-focus-border-color);
                    outline-offset: rem(-2px);
                }
            }

            &.open {
                > .dropdown-toggle {
                    &:focus {
                        outline-color: var(--navigation-secondary-item-focus-border-color);
                    }
                }
            }
        }
    }
}

/* navbar */
.navbar-primary {
    .navbar-collapse {
        .nav {
            li {
                a {
                    &:hover{
                        background: var(--navigation-primary-item-hover-bg);
                    }
                }
            }
        }

        .first-level.navbar-right {
            > .icon {
                & > a {

                    &:hover {
                        &:after {
                            background-color: var(--navigation-primary-item-hover-bg);
                        }
                    }

                    &:focus {
                        &:before {
                            background-color: var(--navigation-default-item-focus-border-color);
                        }
                    }

                    .badge {
                        color:  var(--navigation-default-badge-text-color);
                        background-color: var(--navigation-default-badge-bg);
                        box-shadow: var(--navigation-default-badge-shadow); 
                    }
                }

                &:not(.dropdown) > a {
                    border-radius: rem(14px);

                    &:hover {
                        background: none;
                    }
                }

                &.active {
                    > a {
                        background-color: transparent;

                        &:after {
                            background-color: var(--navigation-primary-item-active-bg);
                        }
                    }

                    .vismaicon:not(.vismaicon-filled)::before { background-color: var(--navigation-default-icon-bg); }
                }

                &.open > a {
                    .badge {
                        background: var(--badge-success-bg);
                        color: var(--badge-success-text);
                    }
                }

                &.dropdown {
                    > a.dropdown-toggle {
                        &:focus {
                            outline: rem(2px) solid var(--navigation-default-item-focus-border-color);
                        }
                    }

                    &.open {
                        > a.dropdown-toggle {
                            > .vismaicon:before { background-color: var(--navigation-default-dropdown-open-icon-bg); }

                            &:focus {
                                outline: rem(2px) solid var(--navigation-secondary-item-focus-border-color);
                            }
                        }
                    }
                }
            }

            .user-dropdown {
                > .dropdown-toggle {
                    > small { color: var(--navigation-default-item-dropdown-small-text-color); }
                }

                &.icon {
                    &.open {
                        .dropdown-toggle {
                            .vismaicon::before { background-color: var(--navigation-default-dropdown-open-icon-bg); }
                        }
                    }
                }

                &.open {
                    > .dropdown-toggle {
                        &:focus {
                            outline-color: var(--navigation-secondary-item-focus-border-color);
                        }
                    }
                }
            }
        }

        .first-level {
            > li > a {
                color: var(--navigation-default-item-text-color);

                &:focus {
                    outline: rem(2px) solid var(--navigation-default-item-focus-border-color);
                    outline-offset: rem(-2px);
                }
            }

            > .active {
                > a { background-color: var( --navigation-primary-item-active-bg); }
            }

            .menudrop {
                > .dropdown-toggle {
                    .icon-align-justify {
                        background: var(--navigation-default-icon-bg);

                        &::before, &::after { background: var(--navigation-default-icon-bg); }
                    }
                }
            }
        }

        ul.second-level {
            border-top: rem(1px) solid var(--navigation-default-second-level-divider);
            box-shadow: var(--navigation-default-second-level-shadow);
        }
    }
}


/* Mobile */
.navbar-primary {
    &.navbar.is-mobile {
        .navbar-collapse{
            .first-level {
                .menudrop {
                    .icon-align-justify {
                        &,
                        &::before,
                        &::after { background: var(--navigation-default-icon-bg); }
                    }
                }
            }
        }
    }
}
