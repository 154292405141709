
// Input in Groups
.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 0 $spacing-8 0;

    align-items: center;

    // wrapper for the cta in the group
    .input-group-btn {
        display: flex;

        &, &.dropdown {
            margin: 0;
            z-index: 1;

            .btn {  margin: 0; }
        }
    }

    .btn-group, .dropdown {
        margin: 0;

        .btn {
            &:hover { z-index: 2; }
            &:focus { z-index: 3; }
        }

        &:not(:last-child) {
            .dropdown-toggle {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &:last-child {
            .btn:first-child {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    .dropdown.open,
    .btn-split.open {
        z-index: 4;
    }

    > .btn {
        margin: 0;

        &:hover { z-index: 2; }
        &:focus { z-index: 3; }

        &:not(:last-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    // styling the input
    > .form-control {
        position: relative;
        flex: 1 1 auto;
        width: 1%;
        margin: 0;
        z-index: 1;

        &:not(:first-child) { margin-left: rem(-1px); }
        &:not(:last-child) { margin-right: rem(-1px); }

        &:hover { z-index: 2; }
        &:focus { z-index: 3; }
    }
}

// Styling wrapper for grouping form elements
.form-group {
    clear: both;
    margin-bottom: $spacing-24;
    position: relative;

    .btn { margin-bottom: 0; }

    label {
        padding: $label-padding-y $label-padding-x rem(5px) 0;
        display: inline-block;
        position: relative;
        line-height: 1.6;

        color: var(--input-label-text-color);

        &.checkbox, &.radio {
            padding: rem(1px) 0 rem(1px) rem(19px);
        }
    }

    .disabled &, :disabled &,
    &.disabled, &:disabled {
        label, .label, .holder {
            color: var(--input-label-disabled-text-color);
        }
    }

    // Required fields
    &.required  {
        > label,
        > a {
            padding-left: $spacing-8;

            &:before {
                position: absolute;
                content: "*";
                margin-left: rem(-7px);
                top: rem(5px);
    
                color: var(--input-error-text-color);
                font-size: $font-size-xs;
            }
        }
    }

    .form-horizontal & {
        display: flex;
        flex-wrap: wrap;

        label + [class*="col-"] { padding: 0; }

        &.required  {
            > label,
            > a {
                &:before {
                    top: rem(3px);
                }
            }
        }
    }

    // form elements inline
    .form-inline & {
        display: inline-block;
        padding-right: $spacing-16;
        margin-bottom: $spacing-16;

        .form-control {
            width: auto;

            @media (min-width: $screen-sm-min) {
                display: inline-block;
                margin-bottom: 0;

                &.select-wrapper select { margin-top: rem(-1px); }
            }
        }
    }

    .input-group {
        margin: 0;
        flex-wrap: nowrap;
    }
}

//
.form-group-lg {
    input{
        &, &.form-control {
            height: $input-btn-height-lg;
        }
    }

    label, .control-label {
        height: $input-btn-height-lg;
        padding-top: $input-btn-padding-y-t;
        line-height: $input-btn-line-height-lg;
    }
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    .btn { margin-bottom: $spacing-16; }

    .checkbox,
    .radio {
        padding-right: $spacing-16;
        margin-bottom: $spacing-16;
    }
} 

.form-horizontal {
    .form-group > [class*="offset-"] { padding: 0; }
}

// wrapper for text to be align with inputs
.form-control-static {
    min-height: $input-btn-height;
    margin-bottom: 0;
    padding-top: $input-btn-padding-y-t;
    padding-bottom: $input-btn-padding-y-b;
    line-height: 1.6;
    margin-bottom: $spacing-24;
}

// Help block
.help-block {
    display: block;
    margin-bottom: 0;
    color: $neutral-80
    }