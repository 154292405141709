
// Progress circle general style

.progress-circle {
	position: relative;
	width: rem(68px);
	height: rem(68px);
	transform: rotate(-90deg);

	.progress_svg {
		position: relative;
		width: 100%;
		height: 100%;
	}

	.progress-bar,
	.full-circle {
		width: 100%;
		height: 100%;
		fill: none;
		transform: translate(4px, 4px); 
		stroke-width: 8;
	}

	.progress-bar {
		stroke-dasharray: 190;
		stroke-dashoffset: 190;
		stroke: var(--progress-bar-bg);
		stroke-linecap: round;
		transition: all 1s ease-in-out;
	}

	.full-circle {
		stroke: var(--progress-bar-wrapper-bg);
	}

	.percentage {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(90deg);
		padding: $spacing-4;
		display: block;
		width: 100%;
		text-align: center;
	}

	// Thin version
	&.progress-circle-xs {
		.progress-bar,
		.full-circle {
			transform: translate(2px, 2px); 
			stroke-width: 4;
		}
	}
}