
/**** Individual icons - worksurface icons ****/

.vismaicon-print {
    &:before {
        mask-image: url('#{$icons-path}/worksurface/24_print.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/24_print.svg');
    }
    &.vismaicon-sm:before {
        mask-image: url('#{$icons-path}/worksurface/16_print.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/16_print.svg');
    }
}

.vismaicon-edit {
    &:before {
        mask-image: url('#{$icons-path}/worksurface/24_edit.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/24_edit.svg');
    }
    &.vismaicon-sm:before {
        mask-image: url('#{$icons-path}/worksurface/16_edit.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/16_edit.svg');
    }
}

.vismaicon-save {
    &:before {
        mask-image: url('#{$icons-path}/worksurface/24_save.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/24_save.svg');
    }
    &.vismaicon-sm:before {
        mask-image: url('#{$icons-path}/worksurface/16_save.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/16_save.svg');
    }
}

.vismaicon-delete {
    &:before {
        mask-image: url('#{$icons-path}/worksurface/24_delete.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/24_delete.svg');
    }
    &.vismaicon-sm:before {
        mask-image: url('#{$icons-path}/worksurface/16_delete.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/16_delete.svg');
    }
}

.vismaicon-attach {
    &:before {
        mask-image: url('#{$icons-path}/worksurface/24_attach.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/24_attach.svg');
    }
    &.vismaicon-sm:before {
        mask-image: url('#{$icons-path}/worksurface/16_attach.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/16_attach.svg');
    }
}

.vismaicon-briefcase {
    &:before {
        mask-image: url('#{$icons-path}/worksurface/24_briefcase.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/24_briefcase.svg');
    }
    &.vismaicon-sm:before {
        mask-image: url('#{$icons-path}/worksurface/16_briefcase.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/16_briefcase.svg');
    }
}

.vismaicon-new {
    &:before {
        mask-image: url('#{$icons-path}/worksurface/24_new.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/24_new.svg');
    }
    &.vismaicon-sm:before {
        mask-image: url('#{$icons-path}/worksurface/16_new.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/16_new.svg');
    }
}

.vismaicon-comment {
    &:before {
        mask-image: url('#{$icons-path}/worksurface/24_comment.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/24_comment.svg');
    }
    &.vismaicon-sm:before {
        mask-image: url('#{$icons-path}/worksurface/16_comment.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/16_comment.svg');
    }
}

.vismaicon-unread-comment {
    &:before {
        mask-image: url('#{$icons-path}/worksurface/24_unread_comment.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/24_unread_comment.svg');
    }
    &.vismaicon-sm:before {
        mask-image: url('#{$icons-path}/worksurface/16_unread_comment.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/16_unread_comment.svg');
    }
}

.vismaicon-add-comment {
    &:before {
        mask-image: url('#{$icons-path}/worksurface/24_add_comment.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/24_add_comment.svg');
    }
    &.vismaicon-sm:before {
        mask-image: url('#{$icons-path}/worksurface/16_add_comment.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/16_add_comment.svg');
    }
}

.vismaicon-zoom-in {
    &:before {
        mask-image: url('#{$icons-path}/worksurface/24_zoom_in.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/24_zoom_in.svg');
    }
    &.vismaicon-sm:before {
        mask-image: url('#{$icons-path}/worksurface/16_zoom_in.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/16_zoom_in.svg');
    }
}

.vismaicon-zoom-out {
    &:before {
        mask-image: url('#{$icons-path}/worksurface/24_zoom_out.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/24_zoom_out.svg');
    }
    &.vismaicon-sm:before {
        mask-image: url('#{$icons-path}/worksurface/16_zoom_out.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/16_zoom_out.svg');
    }
}

.vismaicon-undo {
    &:before {
        mask-image: url('#{$icons-path}/worksurface/24_undo.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/24_undo.svg');
    }
    &.vismaicon-sm:before {
        mask-image: url('#{$icons-path}/worksurface/16_undo.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/16_undo.svg');
    }
}

.vismaicon-redo {
    &:before {
        mask-image: url('#{$icons-path}/worksurface/24_redo.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/24_redo.svg');
    }
    &.vismaicon-sm:before {
        mask-image: url('#{$icons-path}/worksurface/16_redo.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/16_redo.svg');
    }
}

.vismaicon-flag {
    &:before {
        mask-image: url('#{$icons-path}/worksurface/24_flag.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/24_flag.svg');
    }
    &.vismaicon-sm:before {
        mask-image: url('#{$icons-path}/worksurface/16_flag.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/16_flag.svg');
    }
}

.vismaicon-cut {
    &:before {
        mask-image: url('#{$icons-path}/worksurface/24_cut.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/24_cut.svg');
    }
    &.vismaicon-sm:before {
        mask-image: url('#{$icons-path}/worksurface/16_cut.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/16_cut.svg');
    }
}

.vismaicon-copy {
    &:before {
        mask-image: url('#{$icons-path}/worksurface/24_copy.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/24_copy.svg');
    }
    &.vismaicon-sm:before {
        mask-image: url('#{$icons-path}/worksurface/16_copy.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/16_copy.svg');
    }
}

.vismaicon-paste {
    &:before {
        mask-image: url('#{$icons-path}/worksurface/24_paste.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/24_paste.svg');
    }
    &.vismaicon-sm:before {
        mask-image: url('#{$icons-path}/worksurface/16_paste.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/16_paste.svg');
    }
}

.vismaicon-report {
    &:before {
        mask-image: url('#{$icons-path}/worksurface/24_report.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/24_report.svg');
    }
    &.vismaicon-sm:before {
        mask-image: url('#{$icons-path}/worksurface/16_report.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/16_report.svg');
    }
}

.vismaicon-home {
    &:before {
        mask-image: url('#{$icons-path}/worksurface/24_home.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/24_home.svg');
    }
    &.vismaicon-sm:before {
        mask-image: url('#{$icons-path}/worksurface/16_home.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/16_home.svg');
    }
}

.vismaicon-refresh {
    &:before {
        mask-image: url('#{$icons-path}/worksurface/24_refresh.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/24_refresh.svg');
    }
    &.vismaicon-sm:before {
        mask-image: url('#{$icons-path}/worksurface/16_refresh.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/16_refresh.svg');
    }
}

.vismaicon-employee,
.vismaicon-user {
    &:before {
        mask-image: url('#{$icons-path}/worksurface/24_user.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/24_user.svg');
    }
    &.vismaicon-sm:before {
        mask-image: url('#{$icons-path}/worksurface/16_user.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/16_user.svg');
    }
}

.vismaicon-users {
    &:before {
        mask-image: url('#{$icons-path}/worksurface/24_users.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/24_users.svg');
    }
    &.vismaicon-sm:before {
        mask-image: url('#{$icons-path}/worksurface/16_users.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/16_users.svg');
    }
}

.vismaicon-time {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_time.svg');
        mask-image: url('#{$icons-path}/worksurface/24_time.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface/16_time.svg');
        mask-image: url('#{$icons-path}/worksurface/16_time.svg');
    }
}

.vismaicon-reminder {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_reminder.svg');
        mask-image: url('#{$icons-path}/worksurface/24_reminder.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface/16_reminder.svg');
        mask-image: url('#{$icons-path}/worksurface/16_reminder.svg');
    }
}

.vismaicon-search {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_search.svg');
        mask-image: url('#{$icons-path}/worksurface/24_search.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface/16_search.svg');
        mask-image: url('#{$icons-path}/worksurface/16_search.svg');
    }
}

.vismaicon-note {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_note.svg');
        mask-image: url('#{$icons-path}/worksurface/24_note.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface/16_note.svg');
        mask-image: url('#{$icons-path}/worksurface/16_note.svg');
    }
}

.vismaicon-graph,
.vismaicon-graphs {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_graphs.svg');
        mask-image: url('#{$icons-path}/worksurface/24_graphs.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface/16_graphs.svg');
        mask-image: url('#{$icons-path}/worksurface/16_graphs.svg');
    }
}

.vismaicon-email {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_email.svg');
        mask-image: url('#{$icons-path}/worksurface/24_email.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface/16_email.svg');
        mask-image: url('#{$icons-path}/worksurface/16_email.svg');
    }
}

.vismaicon-phone {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_phone.svg');
        mask-image: url('#{$icons-path}/worksurface/24_phone.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface/16_phone.svg');
        mask-image: url('#{$icons-path}/worksurface/16_phone.svg');
    }
}

.vismaicon-locked {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_locked.svg');
        mask-image: url('#{$icons-path}/worksurface/24_locked.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface/16_locked.svg');
        mask-image: url('#{$icons-path}/worksurface/16_locked.svg');
    }
}

.vismaicon-unlocked {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_unlocked.svg');
        mask-image: url('#{$icons-path}/worksurface/24_unlocked.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface/16_unlocked.svg');
        mask-image: url('#{$icons-path}/worksurface/16_unlocked.svg');
    }
}

.vismaicon-price,
.vismaicon-money {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_money.svg');
        mask-image: url('#{$icons-path}/worksurface/24_money.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface/16_money.svg');
        mask-image: url('#{$icons-path}/worksurface/16_money.svg');
    }
}

.vismaicon-web {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_web.svg');
        mask-image: url('#{$icons-path}/worksurface/24_web.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface/16_web.svg');
        mask-image: url('#{$icons-path}/worksurface/16_web.svg');
    }
}

.vismaicon-open {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_open.svg');
        mask-image: url('#{$icons-path}/worksurface/24_open.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface/16_open.svg');
        mask-image: url('#{$icons-path}/worksurface/16_open.svg');
    }
}

.vismaicon-upload {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_upload.svg');
        mask-image: url('#{$icons-path}/worksurface/24_upload.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface/16_upload.svg');
        mask-image: url('#{$icons-path}/worksurface/16_upload.svg');
    }
}

.vismaicon-download {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_download.svg');
        mask-image: url('#{$icons-path}/worksurface/24_download.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface/16_download.svg');
        mask-image: url('#{$icons-path}/worksurface/16_download.svg');
    }
}

.vismaicon-preview {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_preview.svg');
        mask-image: url('#{$icons-path}/worksurface/24_preview.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface/16_preview.svg');
        mask-image: url('#{$icons-path}/worksurface/16_preview.svg');
    }
}

.vismaicon-favourite {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_favourite.svg');
        mask-image: url('#{$icons-path}/worksurface/24_favourite.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface/16_favourite.svg');
        mask-image: url('#{$icons-path}/worksurface/16_favourite.svg');
    }
}

.vismaicon-like {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_like.svg');
        mask-image: url('#{$icons-path}/worksurface/24_like.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface/16_like.svg');
        mask-image: url('#{$icons-path}/worksurface/16_like.svg');
    }
}

.vismaicon-dislike {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_dislike.svg');
        mask-image: url('#{$icons-path}/worksurface/24_dislike.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface/16_dislike.svg');
        mask-image: url('#{$icons-path}/worksurface/16_dislike.svg');
    }
}

.vismaicon-filter {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_filter.svg');
        mask-image: url('#{$icons-path}/worksurface/24_filter.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface/16_filter.svg');
        mask-image: url('#{$icons-path}/worksurface/16_filter.svg');
    }
}

.vismaicon-office-word {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_word.svg');
        mask-image: url('#{$icons-path}/worksurface/24_word.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface/16_word.svg');
        mask-image: url('#{$icons-path}/worksurface/16_word.svg');
    }
}

.vismaicon-office-powerpoint {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_powerpoint.svg');
        mask-image: url('#{$icons-path}/worksurface/24_powerpoint.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface/16_powerpoint.svg');
        mask-image: url('#{$icons-path}/worksurface/16_powerpoint.svg');
    }
}

.vismaicon-office-excel {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_excel.svg');
        mask-image: url('#{$icons-path}/worksurface/24_excel.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface/16_excel.svg');
        mask-image: url('#{$icons-path}/worksurface/16_excel.svg');
    }
}

.vismaicon-export {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_export.svg');
        mask-image: url('#{$icons-path}/worksurface/24_export.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface/16_export.svg');
        mask-image: url('#{$icons-path}/worksurface/16_export.svg');
    }
}

.vismaicon-pdf {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_pdf.svg');
        mask-image: url('#{$icons-path}/worksurface/24_pdf.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface/16_pdf.svg');
        mask-image: url('#{$icons-path}/worksurface/16_pdf.svg');
    }
}

.vismaicon-changelog {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_changelog.svg');
        mask-image: url('#{$icons-path}/worksurface/24_changelog.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface/16_changelog.svg');
        mask-image: url('#{$icons-path}/worksurface/16_changelog.svg');
    }
}

.vismaicon-replace {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_replace.svg');
        mask-image: url('#{$icons-path}/worksurface/24_replace.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-drag {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_drag.svg');
        mask-image: url('#{$icons-path}/worksurface/24_drag.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-image-document {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_image_document.svg');
        mask-image: url('#{$icons-path}/worksurface/24_image_document.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-xml-document {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_xml_document.svg');
        mask-image: url('#{$icons-path}/worksurface/24_xml_document.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-add-user {
    &:before { -webkit-mask-image:  url('#{$icons-path}/worksurface/24_add_user.svg');
        mask-image: url('#{$icons-path}/worksurface/24_add_user.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-alert {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_alert.svg');
        mask-image: url('#{$icons-path}/worksurface/24_alert.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-announcement {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_announcement.svg');
        mask-image: url('#{$icons-path}/worksurface/24_announcement.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-archive-document {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_archive_document.svg');
        mask-image: url('#{$icons-path}/worksurface/24_archive_document.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface/16_archive_document.svg');
        mask-image: url('#{$icons-path}/worksurface/16_archive_document.svg');
    }
}

.vismaicon-breadcrumb-folder {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_breadcrumb_folder.svg');
        mask-image: url('#{$icons-path}/worksurface/24_breadcrumb_folder.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-contract {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_contract.svg');
        mask-image: url('#{$icons-path}/worksurface/24_contract.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image:  none;
        mask-image: none;
    }
}

.vismaicon-database {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_database.svg');
        mask-image: url('#{$icons-path}/worksurface/24_database.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-detach {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_detach.svg');
        mask-image: url('#{$icons-path}/worksurface/24_detach.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-document-info {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_document_info.svg');
        mask-image: url('#{$icons-path}/worksurface/24_document_info.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-education {
    &:before {
        mask-image: url('#{$icons-path}/worksurface/24_education.svg');
        -webkit-mask-image: url('#{$icons-path}/worksurface/24_education.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-enter-full-screen {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_enter_full_screen.svg');
        mask-image: url('#{$icons-path}/worksurface/24_enter_full_screen.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-exit-full-screen {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_exit_full_screen.svg');
        mask-image: url('#{$icons-path}/worksurface/24_exit_full_screen.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-evaluate {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_evaluate.svg');
        mask-image: url('#{$icons-path}/worksurface/24_evaluate.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image:  none;
        mask-image: none;
    }
}

.vismaicon-exclude {
    &:before { -webkit-mask-image:  url('#{$icons-path}/worksurface/24_exclude.svg');
        mask-image: url('#{$icons-path}/worksurface/24_exclude.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image:  none;
        mask-image: none;
    }
}

.vismaicon-folder-building {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_folder_building.svg');
        mask-image: url('#{$icons-path}/worksurface/24_folder_building.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-hourglass {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_hourglass.svg');
        mask-image: url('#{$icons-path}/worksurface/24_hourglass.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-link {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_link.svg');
        mask-image: url('#{$icons-path}/worksurface/24_link.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-location {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_location.svg');
        mask-image: url('#{$icons-path}/worksurface/24_location.svg');
    }
    &.vismaicon-sm:before {
        mask-image: none;
        -webkit-mask-image: none;
    }
}

.vismaicon-logon-failed {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_logon_failed.svg');
        mask-image: url('#{$icons-path}/worksurface/24_logon_failed.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-lunch {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_lunch.svg');
        mask-image: url('#{$icons-path}/worksurface/24_lunch.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-note-blank {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_note_blank.svg');
        mask-image: url('#{$icons-path}/worksurface/24_note_blank.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-note-filled {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_note_filled.svg');
        mask-image: url('#{$icons-path}/worksurface/24_note_filled.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-office-building {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_office_building.svg');
        mask-image: url('#{$icons-path}/worksurface/24_office_building.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-panel {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_panel.svg');
        mask-image: url('#{$icons-path}/worksurface/24_panel.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-price {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_price.svg');
        mask-image: url('#{$icons-path}/worksurface/24_price.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-rotate-ccw {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_rotate_ccw.svg');
        mask-image: url('#{$icons-path}/worksurface/24_rotate_ccw.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image:  none;
        mask-image: none;
    }
}

.vismaicon-rotate-cw {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_rotate_cw.svg');
        mask-image: url('#{$icons-path}/worksurface/24_rotate_cw.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image:  none;
        mask-image: none;
    }
}

.vismaicon-secret-document {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_secret_document.svg');
        mask-image: url('#{$icons-path}/worksurface/24_secret_document.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image:  none;
        mask-image: none;
    }
}

.vismaicon-send {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_send.svg');
        mask-image: url('#{$icons-path}/worksurface/24_send.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface/16_send.svg');
        mask-image: url('#{$icons-path}/worksurface/16_send.svg');
    }
}

.vismaicon-sending-failed {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_sending_failed.svg');
        mask-image: url('#{$icons-path}/worksurface/24_sending_failed.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-shared {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_shared_with_me.svg');
        mask-image: url('#{$icons-path}/worksurface/24_shared_with_me.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-sound {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_sound.svg');
        mask-image: url('#{$icons-path}/worksurface/24_sound.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-text-document {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_text_document.svg');
        mask-image: url('#{$icons-path}/worksurface/24_text_document.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-undefined-document {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_undefined_document.svg');
        mask-image: url('#{$icons-path}/worksurface/24_undefined_document.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image:  none;
        mask-image: none;
    }
}

.vismaicon-user-folder {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_user_folder.svg');
        mask-image: url('#{$icons-path}/worksurface/24_user_folder.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-user-placeholder {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_user_placeholder.svg');
        mask-image: url('#{$icons-path}/worksurface/24_user_placeholder.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image:  none;
        mask-image: none;
    }
}

.vismaicon-video {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_video.svg');
        mask-image: url('#{$icons-path}/worksurface/24_video.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-xml {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_xml.svg');
        mask-image: url('#{$icons-path}/worksurface/24_xml.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-filter-column {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_filter_column.svg');
        mask-image: url('#{$icons-path}/worksurface/24_filter_column.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-agreement {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_agreement.svg');
        mask-image: url('#{$icons-path}/worksurface/24_agreement.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-change-owner {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_change_owner.svg');
        mask-image: url('#{$icons-path}/worksurface/24_change_owner.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-break,
.vismaicon-coffee-break {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_coffee_break.svg');
        mask-image: url('#{$icons-path}/worksurface/24_coffee_break.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image:  none;
        mask-image: none;
    }
}

.vismaicon-comment-chat {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_comment_chat.svg');
        mask-image: url('#{$icons-path}/worksurface/24_comment_chat.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-comment-community {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_comment_community.svg');
        mask-image: url('#{$icons-path}/worksurface/24_comment_community.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-comment-web-reply {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_comment_web_reply.svg');
        mask-image: url('#{$icons-path}/worksurface/24_comment_web_reply.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-jira {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_jira.svg');
        mask-image: url('#{$icons-path}/worksurface/24_jira.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image:  none;
        mask-image: none;
    }
}

.vismaicon-list-view {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_list_view.svg');
        mask-image: url('#{$icons-path}/worksurface/24_list_view.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-payroll-calculations {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_payroll_calculations.svg');
        mask-image: url('#{$icons-path}/worksurface/24_payroll_calculations.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-platform-cloud {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_platform_cloud.svg');
        mask-image: url('#{$icons-path}/worksurface/24_platform_cloud.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image:  none;
        mask-image: none;
    }
}

.vismaicon-platform-mobile {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_platform_mobile.svg');
        mask-image: url('#{$icons-path}/worksurface/24_platform_mobile.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-sort-list {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_sort_list.svg');
        mask-image: url('#{$icons-path}/worksurface/24_sort_list.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image:  none;
        mask-image: none; 
    }
}

.vismaicon-storage {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_storage.svg');
        mask-image: url('#{$icons-path}/worksurface/24_storage.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-tile-view {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_tile_view.svg');
        mask-image: url('#{$icons-path}/worksurface/24_tile_view.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-help {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_help.svg');
        mask-image: url('#{$icons-path}/worksurface/24_help.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    } 
}

.vismaicon-alarm {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_alarm.svg');
        mask-image: url('#{$icons-path}/worksurface/24_alarm.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface/16_pdf.svg');
        mask-image: url('#{$icons-path}/worksurface/16_pdf.svg');
    }
}

.vismaicon-calendar {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_calendar.svg');
        mask-image: url('#{$icons-path}/worksurface/24_calendar.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image:  none;
        mask-image: none;
    }
}

.vismaicon-document_production {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_document_production.svg');
        mask-image: url('#{$icons-path}/worksurface/24_document_production.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
    mask-image: none;
}
}

.vismaicon-info {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_info.svg');
        mask-image: url('#{$icons-path}/worksurface/24_info.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-library {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_library.svg');
        mask-image: url('#{$icons-path}/worksurface/24_library.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-logout {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_logout.svg');
        mask-image: url('#{$icons-path}/worksurface/24_logout.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-pin {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_pin.svg');
        mask-image: url('#{$icons-path}/worksurface/24_pin.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-settings {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_settings.svg');
        mask-image: url('#{$icons-path}/worksurface/24_settings.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-shopping_cart {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_shopping_cart.svg');
        mask-image: url('#{$icons-path}/worksurface/24_shopping_cart.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image:  none;
        mask-image: none;
    }
}

.vismaicon-tools {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_tools.svg');
        mask-image: url('#{$icons-path}/worksurface/24_tools.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image: none;
        mask-image: none;
    }
}

.vismaicon-user_settings {
    &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_user_settings.svg');
        mask-image: url('#{$icons-path}/worksurface/24_user_settings.svg');
    }
    &.vismaicon-sm:before { -webkit-mask-image:  none;
        mask-image: none;
    }
}

// .vismaicon-summarize {
//     &:before { -webkit-mask-image: url('#{$icons-path}/worksurface/24_summarize.svg');
//         mask-image: url('#{$icons-path}/worksurface/24_summarize.svg');
//     }
//     &.vismaicon-sm:before { -webkit-mask-image: url('#{$icons-path}/worksurface/16_summarize.svg');
//         mask-image: url('#{$icons-path}/worksurface/16_summarize.svg');
//     }
// }



/**** Interface controls icons ****/
.vismaicon-datepicker {
    &:before { 
        -webkit-mask-image: url('#{$icons-path}/interface-controls/datepicker.svg');
        mask-image: url('#{$icons-path}/interface-controls/datepicker.svg');
    }
}

.vismaicon-timepicker {
    &:before { 
        -webkit-mask-image: url('#{$icons-path}/interface-controls/timepicker.svg');
        mask-image: url('#{$icons-path}/interface-controls/timepicker.svg');
    }
}

.vismaicon-autocomplete {
    &:before {
        -webkit-mask-image: url('#{$icons-path}/interface-controls/autocomplete.svg');
        mask-image: url('#{$icons-path}/interface-controls/autocomplete.svg');
    }
}

.vismaicon-multiselection {
    &:before {
        -webkit-mask-image: url('#{$icons-path}/interface-controls/multiselection.svg');
        mask-image: url('#{$icons-path}/interface-controls/multiselection.svg');
    }
}

.vismaicon-timepicker,
.vismaicon-datepicker,
.vismaicon-multiselection,
.vismaicon-autocomplete {
    &::before {
        width: rem(16px);
        height: rem(16px);
        -webkit-mask-position: center;
        mask-position: center;
    }
}