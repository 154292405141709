
// Top navigation - secondary styles - WHITE VERSION

.navbar {
    &.navbar-secondary {
        background: var(--navigation-secondary-bg);
        box-shadow: var(--navigation-secondary-shadow);

        .dropdown {
            > .dropdown-toggle {
                .caret {
                    background-color: var(--caret-default-bg);
                }

                &:hover {
                    background-color: var(--navigation-secondary-item-hover-bg);
                }
            }

            &.open {
                > .dropdown-toggle {
                    .caret {
                        background-color: var(--caret-default-bg);
                    }
                }
            }  
        }
    }
}


/* Product dropdown area */
.navbar-secondary {
    .navbar-header {
        .navbar-brand {
            > a,
            > .dropdown-toggle {
                color: var(--navigation-secondary-item-text-color);

                &:hover { background-color: var(--navigation-secondary-item-hover-bg); }

                &:focus {
                    outline: rem(2px) solid var(--navigation-secondary-item-focus-border-color);
                    outline-offset: rem(-2px);
                }
            }

            &.open {
                > a {
                    &, &:focus { box-shadow: var(--navigation-default-menudrop-shadow); }
                }
            }
        }
    }
}


/* navbar */
.navbar-secondary {
    .navbar-collapse {
        .nav {
            li {
                a {
                    &:hover{
                        background: var(--navigation-secondary-item-hover-bg);
                    }

                    &:focus { outline: rem(1px) solid var(--anchor-focus-outline); }
                }
            }
        }

        .first-level.navbar-right {
            > .icon {
                & > a {
                    &:hover {
                        &:after {
                            background-color: var(--navigation-secondary-item-hover-bg);
                        }
                    }

                    &:focus {
                        &:before {
                            background-color: var(--anchor-focus-outline);
                        }
                    }

                    .badge {
                        color:  var(--badge-success-text);
                        background-color: var(--badge-success-bg);
                        box-shadow: var(--badge-success-shadow);
                    }
                }

                &:not(.dropdown) > a {
                    border-radius: rem(14px);

                    &:hover {
                        background: none;
                    }
                }

                &.active {
                    > a {
                        background-color: transparent;
                        box-shadow: none;

                        &:before {
                            background-color: $neutral-light-60;
                            -webkit-mask-image: url(#{$image-path}/squerkle-border-thin.svg);
                            mask-image: url(#{$image-path}/squerkle-border-thin.svg);
                        }

                        &:focus {
                            &:before {
                                -webkit-mask-image: url(#{$image-path}/squerkle-border-thick.svg);
                                mask-image: url(#{$image-path}/squerkle-border-thick.svg);
                                z-index: 0;
                                background-color: var(--anchor-focus-outline);
                            }
                        }
                    }

                    .vismaicon:not(.vismaicon-filled)::before { background-color: var(--navigation-secondary-icon-bg); }
                }

                &.dropdown {
                    > a.dropdown-toggle {
                        &:focus {
                            outline: rem(2px) solid var(--navigation-secondary-item-focus-border-color);
                        }
                    }

                    &.shopping-cart {
                        > a.dropdown-toggle {
                            .vismaicon-shopping-cart:before { background-color: var(--navigation-secondary-hamburger-bg); } 
                        }
                    }
                }
            }
            
            .user-dropdown {
                > .dropdown-toggle {
                    > small { color: var(--navigation-secondary-item-dropdown-small-text-color); }
                }
            }
        }

        .first-level {
            > li > a {
                color: var(--navigation-secondary-item-text-color);

                &:focus {
                    outline: rem(2px) solid var(--navigation-secondary-item-focus-border-color);
                    outline-offset: rem(-2px);
                }
            }

            > .active {
                > a {
                    color: var(--navigation-secondary-item-active-text-color);
                    font-weight: var(--navigation-secondary-item-active-font-weight);
                    background-color: var(--navigation-secondary-item-active-bg);

                    &, &:hover, &:focus, &:active { box-shadow: var(--navigation-secondary-item-active-shadow); }
                }
            }

            > .dropdown.open {
                > a {
                    &, &:focus { box-shadow: var(--navigation-default-menudrop-shadow); }
                }
            }

            .menudrop {
                > .dropdown-toggle {
                    .icon-align-justify {
                        &, &::before, &::after { background: var(--navigation-secondary-menudrop-icon-bg); }
                    }
                }
            }
        }

        ul.second-level {
            border-top: rem(1px) solid var(--navigation-secondary-second-level-divider);
            box-shadow: var(--navigation-secondary-second-level-shadow); 
        }
    }
}

.navbar-secondary {
    &.navbar.is-mobile {
        .navbar-collapse{
            .first-level {
                .menudrop {
                    .icon-align-justify {
                        &,
                        &::before,
                        &::after { background: var(--navigation-secondary-menudrop-icon-bg); }
                    }
                }
            }
        }
    }
}
