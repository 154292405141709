// Spacing in grid system
// stylelint-disable
$spacings-map: () !default;

// Margin and Padding
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacings-map {

        .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }

        .#{$abbrev}t#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-top: $length !important;
        }

        .#{$abbrev}r#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-right: $length !important;
        }
        
        .#{$abbrev}b#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-bottom: $length !important;
        }

        .#{$abbrev}l#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-left: $length !important;
        }

        @if $enable-negative-margins {
          // .m#{$infix}-n#{$size} { #{$prop}: -$length !important; }
          .m#{$infix}-n#{$size} { margin: -#{$length} !important; }

          .mt#{$infix}-n#{$size},
          .my#{$infix}-n#{$size} {
            margin-top: -#{$length} !important;
          }

          .mr#{$infix}-n#{$size},
          .mx#{$infix}-n#{$size} {
            margin-right: -#{$length} !important;
          }
          .mb#{$infix}-n#{$size},
          .my#{$infix}-n#{$size} {
            margin-bottom: -#{$length} !important;
          }
          .ml#{$infix}-n#{$size},
          .mx#{$infix}-n#{$size} {
            margin-left: -#{$length} !important;
          }
        }
      }
    }


    // Top / bottom / left / right
    @each $prop, $abbrev in (top: top, left: left, right: right, bottom: bottom) {
      @each $size, $length in $spacings-map {
        .#{$abbrev}#{$infix}-#{$size} { #{$prop}: $length !important; }

        @if $enable-negative-margins {
          .#{$abbrev}#{$infix}-n#{$size} { #{$prop}: -#{$length} !important; }
        }
      }
    }


  }
}
