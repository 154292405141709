
// Dropdown buttons style declaration
//# Dropdown/dropup wrapper
.dropdown,
.dropup {
    display: inline-flex;
    vertical-align: middle;
    position: relative;
    margin: 0 $spacing-8 $spacing-8 0;

    &.open {
        z-index: 2;
    }
}


//# Dropdown/dropup the trigger/button style.
.dropdown-toggle {
    position: relative;
    text-align: left;
    justify-content: flex-start;
    margin: 0; 
    z-index: 1;
    padding: $input-btn-padding-y-t $input-btn-dropdown-padding-x $input-btn-padding-y-b $input-btn-padding-x;

    &.btn-lg { 
        padding-right: $input-btn-dropdown-padding-x; 

        &::before { 
            top: rem(21px);

            .dropup & {
                top: rem(21px);
            }
        }

    }

    //## drodown arrow style
    &::before {
        display: block;
        content: '';
        position: absolute;
        top: rem(14px);
        right: rem(18px);
        @include caret-small-primary();
        background-color: var(--dropdown-arrow-bg); 

        .dropup & {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
            top: rem(13px);
        }
    }

    .disabled &, :disabled &,
    &.disabled, &:disabled {
        &::before {
            background-color: var(--dropdown-arrow-disabled-bg);
        }
    }

    // removing shadow for open dropdown/dropup
    .open & {
        background-color: var(--button-active-bg);
        box-shadow: none;

        &:not(:focus) {
            border-color: var(--button-active-border-color);
        }

        &:focus:not(:active) {
            box-shadow: inset 0 0 0 rem(1px) var(--button-focus-border-color), $shadow-20 var(--button-focus-shadow-color);
        }
    }

    // alternative style for the dropdown trigger/button
    &.btn-primary {
        &::before {
            background-color: var(--dropdown-primary-arrow-bg);
        }

        &:focus:not(:active) {
            box-shadow: inset 0 0 0 rem(1px) var(--button-primary-focus-border-color), inset 0 0 0 rem(2px) var(--button-primary-focus-inner-shadow-color);
        }

        // removing shadow for open dropdown/dropup
        .open & {
            background-color: var(--button-primary-active-bg);

            &:not(:focus) {
                border-color: var(--button-primary-active-border-color);
            }
        }

        .disabled &, :disabled &,
        &.disabled, &:disabled {

            &::before {
                background-color: var(--dropdown-arrow-disabled-bg);
            }

            &:focus:not(:active) {
                box-shadow: none;
            }
        }
    }
}

//#  Dropdown/Dropup content list
.dropdown-menu {
    display: none;
    position: absolute;
    width: 100%;
    margin: 0;
    padding: rem(10px) 0 $spacing-8 0;
    top: calc(#{'100% - ' rem(2px)});

    list-style: none;

    background-color: var(--dropdown-menu-bg);
    box-shadow: $shadow-30 var(--dropdown-menu-shadow-color);

    // dropup menu list position
    .dropup & {
        top: unset;
        bottom: calc(#{'100% - ' rem(2px)});

        box-shadow: $shadow-30 var(--dropdown-menu-shadow-color);
    }

    .open > & {
        display: block;
    }

    .disabled &, :disabled & {
        display: none;
    }

    a, button {
        display: block;
        overflow: hidden;
        box-sizing: border-box;

        width: 100%;

        color: inherit;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}


//# Dropdown/dropup content items styling
.dropdown-item,
.dropdown-menu > li {
    display: block;
    overflow: hidden;
    box-sizing: border-box;
    // cursor: pointer;

    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: $input-btn-padding-y-t $input-btn-padding-x $input-btn-padding-y-b;

    color: var(--dropdown-menu-text-color);

    text-align: left;
    font-size: $font-size-sm;
    line-height: 1.7;
    text-overflow: ellipsis;
    white-space: nowrap;

    border: 0;
    background-color: var(--dropdown-menu-bg);
    box-shadow: none;

    &:not(a):not(button) {
        padding: 0;

        a, button {
            padding: $input-btn-padding-y-t $input-btn-padding-x $input-btn-padding-y-b;
        }
    }

    // active/selected state for content items
    &.active {
        color: var(--dropdown-menu-selected-text-color);
    }

    // hover state for content items
    &:hover {
        background-color: var(--dropdown-menu-hover-bg);
    }

    // focus state for content items
    &:focus,
    a:focus, button:focus {
        outline: 0;
        box-shadow: inset 0 0 0 rem(2px) var(--button-focus-border-color);
    }

    // pressed state for content items
    &:active,
    a:active, button:active {
        background-color: var(--button-active-bg);
        box-shadow: none;
    }

    // disabled state for content items
    &.disabled, &:disabled {
        &, a, button {
            pointer-events: none;
    
            color: var(--button-disabled-text-color);
            box-shadow: none;
        }
    }

    &.divider {
        &:after {
            background: var(--navigation-default-dropdown-divider-color-strong);
            content: "";
            display: block;
            height: rem(1px);
            //width: 100%;
            width: calc(100% - #{$spacing-32});
            margin: 0 $spacing-16;
        }
    }
}


//# Dropdown/dropup styling in button groups
.btn-group {
    // > .dropdown, > .dropup {
    //     .dropdown-toggle:before {}
    // }

    &.btn-group-lg {
        > .dropdown, > .dropup {
            .dropdown-toggle:before {
                top: rem(21px);
            }
        }
    }

    &.open {
        position: relative;
        z-index: 2;
    }
}
