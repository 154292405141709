
// Tabs graphics variables
$tabs-corner-bg:                    $tabs-graph-path + '/tab-corner.svg';
$tabs-middle-bg:                    $tabs-graph-path + '/tab-middle.svg';
$tabs-dropdown-trigger-bg:          $tabs-graph-path + '/more-tabs-btn.svg';

$tabs-height:                       rem(40px);
$tabs-secondary-height:             rem(80px);
$tabs-background-size:   rem(25px) rem(690px);
$tabs-overflow-icon-background-size: rem(490px) rem(24px);

$tabs-normal-Ypoition: var(--tabs-normal-Ypoition); // #{rem(0px)};
$tabs-disabled-Ypoition: var(--tabs-disabled-Ypoition); // #{rem(-50px)};
$tabs-hover-Ypoition: var(--tabs-hover-Ypoition); // #{rem(-100px)};
$tabs-pressed-Ypoition: var(--tabs-pressed-Ypoition); // #{rem(-150px)};
$tabs-focus-Ypoition: var(--tabs-focus-Ypoition); // #{rem(-200px)};
$tabs-focus-hover-Ypoition: var(--tabs-focus-hover-Ypoition); // #{rem(-250px)};
$tabs-selected-Ypoition: var(--tabs-selected-Ypoition); // #{rem(-250px)};
$tabs-selected-focused-Ypoition:  var(--tabs-selected-focused-Ypoition); // #{rem(-350px)};

$tabs-dropdown-Ypoition: var(--tabs-dropdown-Ypoition); // #{rem(4px)};
$tabs-dropdown-normal-Xpoition: var(--tabs-dropdown-normal-Xpoition); // #{rem(4px)};
$tabs-dropdown-hover-Xpoition: var(--tabs-dropdown-hover-Xpoition); // #{rem(-45px)};
$tabs-dropdown-focus-Xpoition: var(--tabs-dropdown-focus-Xpoition); // #{rem(-94px)};
$tabs-dropdown-pressed-Xpoition: var(--tabs-dropdown-pressed-Xpoition); // #{rem(-143px)};
$tabs-dropdown-disabled-Xpoition: var(--tabs-dropdown-disabled-Xpoition); // #{rem(-192px)};

// Tabs definition
.tab-content {
    display: block;
    content: '';
    border-top: rem(2px) solid var(--tabs-content-border-color);
    background-color: var(--tabs-content-bg);
    box-shadow: $shadow-10 var(--tabs-content-shadow-color); 

    &.tab-content-secondary {
        border-radius: 0 0 rem(8px) rem(8px);
        border-top: rem(1px) solid var(--tabs-content-secondary-border-color);
    }
}

// Tabs general style
.nav-tabs:not(.navbar-nav) {
    display: inline-flex;
    flex-wrap: wrap;
    position: relative;

    height: $tabs-height;
    padding: 0 rem(54px) 0 rem(4px);
    margin: 0;
    top: rem(2px);

    border: 0;
    background: transparent;
    box-shadow: none;

    list-style: none;
    z-index: 1;

    // tabs items basic style
    .nav-item {
        position: relative;
        margin: 0;
        margin-right: rem(-13px);
        padding: 0 rem(18px);
        float: left;

        a {
            display: inline-block;
            cursor: pointer;
            position: relative;
            height: $tabs-height;
            padding: rem(12px) rem(10px) rem(4px);
            line-height: 1;

            color: var(--tabs-text-color);
            white-space: nowrap;
            text-align: center;
            text-decoration: none;

            outline: 0;
            // tabs items middle graphic
            background: {
                color: transparent;
                image: url(#{$tabs-middle-bg});
                position: 0 $tabs-normal-Ypoition;
                repeat: repeat-x;
                size: $tabs-background-size;
            };

            // tabs items corners graphics
            &::before, &::after {
                display: block;
                content: '';
                position: absolute;
                width: rem(22px);
                height: $tabs-height;
                top: 0;

                background: {
                    color: transparent;
                    image: url(#{$tabs-corner-bg});
                    position: 0 $tabs-normal-Ypoition;
                    repeat: no-repeat;
                    size: $tabs-background-size;
                };
            }

            &::before {
                left: rem(-22px);
            }

            &::after {
                right: rem(-22px);
                transform: scaleX(-1);
            }

            // tabs items on hover
            &:hover, &.hover {
                color: var(--tabs-focused-text-color);

                &, &::before, &::after { 
                    background-position-y: $tabs-hover-Ypoition; 
                }
            }

            // tabs items on focus
            &:focus:not(:active), &.focus:not(:active) {
                color: var(--tabs-focused-text-color);

                &, &::before, &::after { background-position-y: $tabs-focus-Ypoition; }

                // tabs items on focus and hover
                &:hover, &.hover {
                    &, &::before, &::after { background-position-y: $tabs-focus-hover-Ypoition; }
                }
            }

            // tabs items on pressed state
            &.pressed, &:active {
                &, &::before, &::after { background-position-y: $tabs-pressed-Ypoition; }
            }
        }

        // tabs items on layer order
        &:nth-last-child(24) { z-index: 24; }
        &:nth-last-child(23) { z-index: 23; }
        &:nth-last-child(22) { z-index: 22; }
        &:nth-last-child(21) { z-index: 21; }
        &:nth-last-child(20) { z-index: 20; }
        &:nth-last-child(19) { z-index: 19; }
        &:nth-last-child(18) { z-index: 18; }
        &:nth-last-child(17) { z-index: 17; }
        &:nth-last-child(16) { z-index: 16; }
        &:nth-last-child(15) { z-index: 15; }
        &:nth-last-child(14) { z-index: 14; }
        &:nth-last-child(13) { z-index: 13; }
        &:nth-last-child(12) { z-index: 12; }
        &:nth-last-child(11) { z-index: 11; }
        &:nth-last-child(10) { z-index: 10; }
        &:nth-last-child( 9) { z-index:  9; }
        &:nth-last-child( 8) { z-index:  8; }
        &:nth-last-child( 7) { z-index:  7; }
        &:nth-last-child( 6) { z-index:  6; }
        &:nth-last-child( 5) { z-index:  5; }
        &:nth-last-child( 4) { z-index:  4; }
        &:nth-last-child( 3) { z-index:  3; }
        &:nth-last-child( 2) { z-index:  2; }
        &:last-child         { z-index:  1; }

        // tabs items selected
        &.active {
            cursor: default;
            pointer-events: none;
            z-index: 29;

            a {
                &, &::before, &::after { background-position-y: $tabs-selected-Ypoition; }
            }

            // tabs items selected and focused
            &.focus, a:focus, a.focus {
                &, &::before, &::after { background-position-y: $tabs-selected-focused-Ypoition; }
            }
        }

        &.pressed, &:active:not(.disabled) {
            //z-index: 28;
            a { 
                &, &::after, &::before {
                    border-bottom: rem(2px) solid var(--tabs-content-border-color);
                }
            }
            
        }

        // tabs items on diusabled state
        &.disabled a, &:disabled a,
        a.disabled, a:disabled {
            &, &:focus {
                cursor: default;
                pointer-events: none;

                color: var(--tabs-disabled-text-color);

                &, &::before, &::after { background-position-y: $tabs-disabled-Ypoition; }
            }
        }
    }

    &.nav-tabs-primary, &.nav-tabs-secondary {
        padding-left: 0;
        top: 0;

        .nav-item {
            margin: 0;
            padding: 0;

            a {
                background-image: none;
                background-image: unset;

                &::before, &::after {
                    display: none;
                }
            }
        }

        .dropdown-menu {
            a {
                border-radius: 0;
                box-shadow: none;

                &:focus:not(:active), &.focus:not(:active) {
                    box-shadow: inset 0 0 0 rem(2px) var(--button-focus-border-color);
                }
            }
        }
    }

    &.nav-tabs-primary {
        .nav-item {
            a {
                padding: rem(12px) rem(16px);
                line-height: rem(16px);
                border-radius: rem(5px) rem(5px) 0 0;
                background-color: transparent;
                box-shadow: none;

                &:hover, &.hover {
                    background-color: var(--button-hover-bg);
                    // box-shadow: $shadow-20 var(--button-shadow-color);
                    box-shadow: rem(2px) 0 rem(4px) rem(-2px) var(--button-shadow-color);
                }

                &:active, &.pressed {
                    background-color: var(--button-active-bg);
                    box-shadow: none;
                }

                &:focus:not(:active), &.focus:not(:active) {
                    background-color: var(--button-bg);
                    box-shadow: inset 0 0 0 rem(2px) var(--button-focus-border-color), inset 0 rem(-4) 0 0 var(--button-focus-border-color);

                    &:hover, &.hover {
                        background-color: var(--button-hover-bg);
                    }
                }
            }

            &.active {
                a {
                    background-color: var(--button-bg);
                    box-shadow: rem(2px) 0 rem(4px) rem(-2px) var(--button-shadow-color);
                }
            }
        }
    }

    &.nav-tabs-secondary {
        height: $tabs-secondary-height;
        padding-left: rem(16px);

        .dropdown {
            height: $tabs-secondary-height;
            top: rem(20px);

            .dropdown-menu {
                top: rem(36px);
            }
        }

        .nav-item {
            a {
                display: flex;
                position: relative;
                align-items: center;
                height: $tabs-secondary-height;
                padding: rem(16px);

                &::before {
                    content: '';
                    position: absolute;
                    width:  calc(#{'100% - ' rem(32px)});
                    height: rem(4px);
                    top: calc(#{$tabs-secondary-height - rem(4px)});
                    left: rem(16px);

                    background: var(--tabs-content-border-color);
                }

                &:hover, &.hover {
                        color: var(--button-hover-border-color);
                }

                &:focus:not(:active), &.focus:not(:active) {
                    &::before {
                        display: block;

                        background: var(--anchor-focus-outline);
                    }
                }
            }

            // tabs items on pressed state
            &.active {
                a {
                    font-weight: $bold;
                    color: var(--tabs-text-color);

                    &::before {
                        display: block;
                    }
                }
            }

            // tabs items on diusabled state
            &.disabled a, &:disabled a,
            a.disabled, a:disabled {
                &, &:focus {
                    color: var(--tabs-disabled-text-color);
                }
            }
        }
    }

    // tabs overflow dropdown
    .tabdrop {
        overflow: visible;
        position: absolute;
        right: rem(2px);
        margin: 0;
        padding: 0;

        border-right: 0;
        box-shadow: none;
        z-index: 30;

        &.hide {
            display: none;
        }

        .caret { display: none; }

        // tabs overflow dropdown trigger
        .dropdown-toggle {
            display: block;
            content: '';
            overflow: hidden;
            padding: 0;
            width: rem(40px);
            height: $tabs-height;
            text-indent: 9999px;


            background: {
                color: transparent;
                image: url(#{$tabs-dropdown-trigger-bg});
                position: $tabs-dropdown-normal-Xpoition $tabs-dropdown-Ypoition;
                repeat: no-repeat;
                size: $tabs-overflow-icon-background-size;
            };

            &::before, &::after { display: none; } 

            // tabs overflow dropdown trigger on hover
            &:hover { background-position-x: $tabs-dropdown-hover-Xpoition; }

            // tabs overflow dropdown trigger on focus
            &:focus {
                outline: 0;

                &:not(:active) {
                    background-position-x: $tabs-dropdown-focus-Xpoition;
                    background-position-y: $tabs-dropdown-Ypoition;
                    box-shadow: none;
                }
            }

            // tabs overflow dropdown trigger on pressed
            &:active { background-position-x: $tabs-dropdown-pressed-Xpoition; }

            .icon-align-justify { display: none; }
        }

        &.disabled .dropdown-toggle,
        &:disabled .dropdown-toggle,
        .dropdown-toggle.disabled,
        .dropdown-toggle:disabled {
            background-position-x: $tabs-dropdown-disabled-Xpoition;
        }

        // tabs overflow dropdown menu
        .dropdown-menu {
            width: auto;
            width: unset;
            min-width: rem(120px);
            // top: rem(34px);
            // right: rem(-12px);
            top: rem(30px);
            right: rem(0);
            left: auto;
            margin: 0;
            padding: rem(8px) 0;

            // tabs overflow dropdown menu items
            li {
                height: rem(36px);
                padding: 0;

                a {
                    height: rem(36px);
                    padding: rem(8px) rem(18px);
                    line-height: $line-height-base;

                    text-align: left;
                    background: transparent;

                    &:focus {
                        outline: none;
                    }

                    &:active {
                        border: 0;
                        color: var(--dropdown-menu-selected-text-color);
                    }
                }

                &.active, &:active {
                    background-color: transparent;
                    
                    a {
                        color: var(--dropdown-menu-selected-text-color);
                    }
                }
            }
        }
    }

// /* Justified tabs */
    &.nav-justified {
        width: 100%;

        &.hide-tabdrop { padding-right: rem(17px); }

        // tabs items style for justify tabs
        .nav-item {
            flex: 1;

            // tabs overflow for justify tabs
            &.tabdrop {
                right: 0;

                > a {
                    width: rem(44px);
                    height: $tabs-height;
                }
            }

            a { width: 100%; }
        }
    }
}
