// Cards list - .card-list-primary

.card-list-secondary {
    .card-list-item {
        background-color: var(--card-list-secondary-bg);
        border-radius: $border-radius-5;
        padding: rem(16px) 0;

        > * {
            padding: rem(8px) rem(30px);
        }

        .description {
            flex-direction: column;
            align-items: initial;
            font-weight: $semibold;

            .date {
                color: var(--card-list-text-color);
                font-weight: $normal;
            }

            p { margin: 0; }
        }

        .action {
            p { margin: 0; }

            .vismaicon { margin: 0 rem(6px); }
        }

        .divider {
            border-color: var(--module-container-divider);
        }
    }
}