
// Panel wrapper
.panel, .panel-group {
    margin-bottom: rem(12px);
    padding: 0;

    background-color: var(--module-container-bg-color);
    box-shadow: var(--module-container-shadow);

    // panel default inner spaceing
    .panel-heading {
        padding: $spacing-32 $spacing-32 $spacing-12;

        &:only-child { padding-bottom: $spacing-32; }
    }

    .panel-body {
        padding: $spacing-12 $spacing-32 $spacing-32;

        &:first-child { padding-top: $spacing-32; }

        > p:only-child { margin-bottom: 0; }
    }

    // Panel heading and title styleing
    .panel-heading {
        &, .panel-title {
            margin: 0;

            color: var(--titles-font-color);
            line-height: rem(24px);
        }

        .panel-title {
            font: {
                size: rem(16px);
                weight: $bold;
            }
        }
    }

    // reset spacing for the depper inner pannel
    .panel-body {
        .panel {
            .panel-heading,
            .panel-body,
            .panel-footer {
                padding-right: 0;
                padding-bottom: 0;
                padding-left: 0;
            }
        }
    }

    // pannel footer styling
    .panel-footer {
        padding: rem(24px) rem(30px);

        border-top: rem(1px) solid var(--module-container-divider);

        @include clearfix();

        > .btn, > .btn-group {
            margin-bottom: 0;
        }
    }

    // remove margin bottom for the last paragraph in panel body
    p:last-child {
        margin-bottom: 0;
    }

    // reset styling for list inside panels
    .list-group {
        border-radius: 0;
        box-shadow: none;

        > .list-group-item:last-child {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    // reset inner spacing for table in panels
    .table {
        box-shadow: 0 rem(1px) rem(1px) 0 rgba($blue-90, 0.1), 0 rem(-1px) 0 0 var(--module-container-divider);

        > thead > tr > th,
        > tbody > tr > td,
        > tbody > tr > th {
            &:first-child {
                padding-left: rem(30px);
            }
            &:last-child {
                padding-right: rem(30px);
            }
        }
    }
}

// Panel groups style
.panel-group {

    // Anchors in panel title
    .panel-title {
        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .panel-heading {
        padding-bottom: 0;
    }

    .panel {
        margin-bottom: 0;
        box-shadow: none;

        .panel {
            .panel-title {
                a {
                    color: var(--titles-font-color);

                    &:hover {
                        color: var(--anchor-hover-color);
                    }
                }
            }
        }
    }
}

