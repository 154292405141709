
// Split
.btn-group {
    &.btn-split {
        position: relative;
        margin: 0 $spacing-8 $spacing-8 0;

        > .btn {
            margin: 0;

            text-decoration: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            text-align: left;
            justify-content: flex-start;

            z-index: 1;

            &:not(.dropdown-toggle) {
                min-width: rem(130px);
                @media (max-width: $screen-md-min) { min-width: rem(90px); }
                @media (max-width: $screen-sm-min) { min-width: rem(70px); }
            }
        }

        //# Dropdown trigger style.
        .dropdown-toggle {
            min-width: $input-btn-height;
            padding: $spacing-4;

            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;

            > .caret { display: none; }

            //## drodown arrow style
            &::before {
                right: rem(11px);
            }

            &, &.btn-primary {
                &::before {
                    background-color: var(--dropdown-arrow-bg);

                    .btn-toolbar-primary & {
                        background-color: var(--dropdown-primary-arrow-bg);
                    }
                }

                //## drodown arrow style for disabled state
                &.disabled, &:disabled {
                    &::before {
                        background-color: var(--dropdown-arrow-disabled-bg);

                        .btn-toolbar-primary & {
                            background-color: var(--dropdown-arrow-disabled-bg);
                        }
                    }
                }
            }
        }

        &.open {
            z-index: 2;

            .dropdown-toggle {
                background-color: var(--button-active-bg);
                box-shadow: rem(-1px) 0 0 0 var(--button-border-color), inset rem(6px) 0 rem(4px) rem(-4px) var(--button-shadow-color);

                &:hover:not(:active) {
                    background-color: var(--button-hover-bg);
                    box-shadow: rem(-1px) 0 0 0 var(--button-hover-border-color), inset rem(6px) 0 rem(4px) rem(-4px) var(--button-shadow-color);
                }

                &:focus:not(:active) {
                    box-shadow: rem(-1px) 0 0 0 var(--button-focus-border-color), inset 0 0 0 rem(1px) var(--button-focus-border-color);
                }

                &.disabled, &:disabled {
                    ~ .dropdown-menu {
                        display: none;
                    }
                }
            }
        }

        .disabled &, :disabled &,
        &.disabled, &:disabled {
            &, &.open {
                .dropdown-toggle {
                    &, &.btn-primary {
                        &::before {
                            background-color: var(--dropdown-arrow-disabled-bg);

                            .btn-toolbar-primary & {
                                background-color: var(--dropdown-arrow-disabled-bg);
                            }
                        }
                    }
                }

                .dropdown-menu {
                    display: none;
                }
            }
        }

        &.btn-group-lg {

            > .btn:not(.dropdown-toggle) {
                min-width: rem(114px);
                @media (max-width: $screen-md-min) { min-width: rem(72px); }
                @media (max-width: $screen-sm-min) { min-width: rem(52px); }
            }

            .dropdown-toggle {
                min-width: $input-btn-height-lg;

                &::before { 
                    top: rem(22px); 
                    right: rem(18px);
                }
            }
        }

        // alternative style for split buttons when is not in 'btn-toolbar'
        :not(.btn-toolbar) & {
            // primary sheme color
            &.btn-split-primary {
                .btn {
                    color: var(--button-primary-text-color);

                    border-color: var(--button-primary-border-color);
                    background-color: var(--button-primary-bg);
                    box-shadow: $shadow-20 var(--button-primary-shadow-color);

                    &.dropdown-toggle {
                        &::before {
                            background-color: var(--dropdown-primary-arrow-bg);
                        }

                        &.disabled, &:disabled {
                            &::before {
                                background-color: var(--dropdown-arrow-disabled-bg);
                            }
                        }
                    }

                    &:hover {
                        border-color: var(--button-primary-hover-border-color);
                        background-color: var(--button-primary-hover-bg);
                    }

                    &:focus {
                        &:not(:active) {
                            border-color: var(--button-primary-focus-border-color);
                            box-shadow: inset 0 0 0 rem(1px) var(--button-primary-focus-border-color), inset 0 0 0 rem(2px) var(--button-primary-focus-inner-shadow-color), $shadow-20 var(--button-primary-focus-shadow-color);

                            +.btn {
                                box-shadow: rem(-1px) 0 0 0 var(--button-focus-border-color), $shadow-20 var(--button-primary-focus-shadow-color);

                                &.disabled, &:disabled {
                                    box-shadow: rem(-1px) 0 0 0 var(--button-focus-border-color), inset rem(6px) 0 rem(4px) rem(-4px) var(--button-shadow-color);
                                }
                            }
                        }
                    }

                    &:active {
                        border-color: var(--button-primary-active-border-color);
                        background-color: var(--button-primary-active-bg);
                    }

                    &.disabled, &:disabled {
                        color: var(--button-disabled-text-color);

                        &, &:focus {
                            border-color: var(--button-disabled-border-color);
                            background-color: var(--button-disabled-bg);
                            box-shadow: none;

                            + .btn {
                                box-shadow: rem(-1px) 0 0 0 var(--button-border-color), rem(-6px) 0 rem(4px) rem(-4px) var(--button-shadow-color), $shadow-20 var(--button-shadow-color);

                                &.disabled, &:disabled {
                                    &, &:focus:not(:active) {
                                        box-shadow: rem(-1px) 0 0 0 var(--button-inner-border-color);
                                    }
                                }
                            }
                        }
                    }

                    + .btn {
                        box-shadow: rem(-1px) 0 0 0 var(--button-primary-inner-border-color), $shadow-20 var(--button-primary-focus-shadow-color);

                        &:focus:not(:active) {
                            box-shadow: rem(-1px) 0 0 0 var(--button-primary-focus-border-color), inset 0 0 0 rem(1px) var(--button-primary-focus-border-color), inset 0 0 0 rem(2px) var(--button-primary-focus-inner-shadow-color), $shadow-20 var(--button-primary-focus-shadow-color);

                            &.disabled, &:disabled {
                                box-shadow: rem(-1px) 0 0 0 var(--button-inner-border-color), inset rem(6px) 0 rem(4px) rem(-4px) var(--button-shadow-color);
                            }
                        }

                        &.disabled, &:disabled {
                            box-shadow: rem(-1px) 0 0 0 var(--button-border-color), inset rem(6px) 0 rem(4px) rem(-4px) var(--button-shadow-color);
                        }
                    }
                }

                // split buttons style for opend dropdown menu
                &.open {
                    .btn.dropdown-toggle {
                        border-color: var(--button-primary-active-border-color);
                        background-color: var(--button-primary-active-bg);
                        box-shadow: rem(-1px) 0 0 0 var(--button-primary-inner-border-color), inset rem(6px) 0 rem(4px) rem(-4px) var(--button-shadow-color);

                        &:hover:not(:active) {
                            border-color: var(--button-primary-hover-border-color);
                            background-color: var(--button-primary-hover-bg);
                            box-shadow: rem(-1px) 0 0 0 $green-70, inset rem(6px) 0 rem(4px) rem(-4px) var(--button-shadow-color);
                        }

                        &:focus:not(:active) {
                            border-color: var(--button-primary-focus-border-color);
                            box-shadow: rem(-1px) 0 0 0 var(--button-primary-focus-border-color), inset 0 0 0 rem(1px) var(--button-primary-focus-border-color), inset 0 0 0 rem(2px) var(--button-primary-focus-inner-shadow-color);
                        }

                        &.disabled, &:disabled {
                            &, &:focus {
                                border-color: var(--button-primary-disabled-border-color);
                                background-color: var(--button-primary-disabled-bg);
                            }
                        }
                    }
                }
            }
        }
    }
}

// split primary color style for disabled state
.disabled:not(.btn-toolbar) .btn-group.btn-split.btn-split-primary,
.disabled:not(.btn-toolbar) .btn-group.btn-split.btn-split-primary.open,
:not(.btn-toolbar):disabled .btn-group.btn-split.btn-split-primary,
:not(.btn-toolbar):disabled .btn-group.btn-split.btn-split-primary.open,
:not(.btn-toolbar) .btn-group.btn-split.btn-split-primary.disabled,
:not(.btn-toolbar) .btn-group.btn-split.btn-split-primary.disabled.open,
:not(.btn-toolbar) .btn-group.btn-split.btn-split-primary:disabled,
:not(.btn-toolbar) .btn-group.btn-split.btn-split-primary.open:disabled {
    .btn {
        &.dropdown-toggle {
            &::before {
                background-color: var(--dropdown-arrow-disabled-bg);
            }
        }

        &, &:focus {
            color: var(--button-disabled-text-color);

            border-color: var(--button-disabled-border-color);
            background-color: var(--button-disabled-bg);
            box-shadow: none;

            + .btn {
                &, &:focus {
                    box-shadow: rem(-1px) 0 0 0 var(--button-inner-border-color);
                }
            }
        }
    }
}
