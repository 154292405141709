
//=== Basic vertical nav style

.page-holder {
    padding-left: rem(300px);
    transition: padding-left 0.2s ease-in-out;

    @media (max-width: $screen-md-min) {
        padding-left: rem(0px);
    }

    &.hidden-vert-menu {
        padding-left: rem(0px);
        transition: padding-left 0.2s ease-in-out;

        @media (max-width: $screen-md-min) {
            padding-left: rem(0px);
        }

        + footer {
            padding-left: rem(0px);
            transition: padding 0.2s ease-in-out;
        }
    }

    + footer {
        padding-left: rem(300px);
        padding-right: rem(30px);
        transition: padding 0.2s ease-in-out;
    }
}

.vertical-nav {
    top: rem(64px); // When top navigation has only one level
    height: calc(#{'100vh - ' rem(64px)}); // When top navigation has only one level
    left: 0;
    position: fixed;
    padding-bottom: rem(122px);
    z-index: 1;
    overflow: visible;
    width: rem(300px);
    transition: left 0.2s ease-in-out;

    &.hidden-menu {
        //border-right: rem(5px) solid var(--vertical-nav-default-collapse-btn-bg);
        left: rem(-295px);
        transition: left 0.2s ease-in-out, border-right 0.2s ease-in-out;

        @media (max-width: $screen-md-min) {
            left: rem(-295px);
        }

        ul li.show-nav {
            right: rem(-35px);
            z-index: 1;
           
            > a {
                transform: rotateY(180deg);
                transition: rotate 0.2s ease-in-out;
                right: rem(0px);
            }
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            list-style: none;
            margin: 0;
            padding: 0;

            a {
                text-decoration: none;
                display: block;
                position: relative;

                &:after {
                    display: block;
                    content: '';
                    position: absolute;
                    top: rem(19px);
                    right: rem(15px);
                    @include caret-large-default();
                }

                &:only-child:after { display: none; }

                &:focus { outline-offset: rem(-2px); }
            }

            &.active,
            &.active.active-child {
                > a {
                    &:after { transform: rotate(180deg); }
                }
            }

            &.disabled {
                > a {
                    pointer-events: none;

                    &:hover { cursor: not-allowed; }

                    &:focus { outline: none; }
                }
            }

            &.show-nav {
                position: absolute;
                right: 0;
                bottom: rem(50px);

                > a {
                    width: rem(30px);
                    height: rem(65px);
                    display: block;
                    text-indent: -9999px;
                    padding: 0 !important;
                    -webkit-mask: url(#{$image-path}/vertical-nav-collapse-btn.svg);
                    mask: url(#{$image-path}/vertical-nav-collapse-btn.svg);
                    -webkit-mask-repeat: no-repeat;
                    mask-repeat: no-repeat;
                    -webkit-mask-size: cover;
                    mask-size: cover;

                    &:after {
                        display: block;
                        content: '';
                        position: absolute;
                        top: rem(26px);
                        right: rem(8px);
                        height: rem(14px);
                        width: rem(14px);
                        -webkit-mask: url(#{$image-path}/vertical-nav-collapse-arrow.svg);
                        mask: url(#{$image-path}/vertical-nav-collapse-arrow.svg);
                        -webkit-mask-repeat: no-repeat;
                        mask-repeat: no-repeat;
                        -webkit-mask-size: cover;
                        mask-size: cover;
                        transition: width 0.2s ease-in-out;
                    }

                    &:focus {
                        -webkit-mask: url(#{$image-path}/vertical-nav-collapse-btn-focus.svg);
                        mask: url(#{$image-path}/vertical-nav-collapse-btn-focus.svg);
                        outline: none; 
                    }
                }

                &.show-nav-primary {
                    > a {
                        width: rem(30px);
                        height: rem(30px);
                        border-radius: rem(5px) 0 0 rem(5px);
                        mask: none;
                        -webkit-mask: none;

                        &:after {
                            top: rem(9px);
                        }

                        &:focus {
                            outline-offset: 2px;
                        }
                    }
                }
            }
        }
    }

    ul {
        overflow-y: auto;
        overflow-x: visible;
        box-sizing: content-box;
        width: rem(300px);
        height: calc(#{'100vh - ' rem(66px)}); // When top navigation has only one level
        // scrollbar-width: none;
        padding-bottom: rem(120px);

        ul {
            padding: 0;
            height: auto;
        }

        > li {
            > a { padding: rem(12px) rem(30px) rem(12px) rem(22px); }

            &.active, &.active-child {
                > ul { display: block; }
            }

            > ul {
                padding-bottom: rem(6px);
                display: none;

                > li {
                    > a { padding: rem(9px) rem(30px); }
                }
            }
        }
    }
}


/* When top navigation/main nav has 2 levels */
.nav-space {
    .vertical-nav {
        @media (min-width: $screen-sm-min) {
            top: rem(110px); // When top navigation has two levels
            height: calc(#{'100vh - ' rem(110px)}); // When top navigation has two levels
        }

        > ul {
            @media (min-width: $screen-sm-min) {
                height: calc(#{'100vh - ' rem(110px)}); // When top navigation has 2 levels level
            }
        }
    }
}
