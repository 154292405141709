
// Stepper - vertical
.stepper.stepper-vertical.stepper-vertical-sm {
    > ul {
        > li {
            top: rem(3px);

            > * {
                padding-left: rem(48px);
                padding-top: 0;
                min-height: rem(45px);

                &:after {
                    width: rem(2px);
                    left: rem(9px);
                }

                &:before {
                    width: rem(16px);
                    height: rem(16px);
                    left: rem(2px);
                }

                > span:not(.step) {
                    top: rem(-5px);
                    position: relative;
                }
            }

            &.active {
                top: 0;

                > * {
                    &:before {
                        width: rem(24px);
                        height: rem(24px);
                        border-width: rem(10px);
                        left: rem(-2px);
                    }

                    > span:not(.step) { top: 0; }
                }
            }

            &:not(:last-child):after {
                width: rem(2px);
                left: rem(13px);
                height: 90%;
                top: rem(20px);
            }

            &.passed {
                > * {
                    > span:not(.step) { top: rem(-4px); }

                    &:before { background-image: none; }
                }
            }
        }
    }
}