.drawer {
    background-color: var(--modal-bg);
    height: 100%;
    position: fixed;
    right: -100%;
    bottom: 0;
    box-shadow: var(--modal-shadow);
    border-radius: $border-radius-20 0 0 $border-radius-20;
    z-index: $zindex-fixed;
    transition: right 800ms ease-in-out;
    padding-top: $spacing-32;

    &, &.drawer-md {
        width: 50%;
        max-width: rem(960px);
    }

    &.drawer-lg {
        width: 75%;
        max-width: rem(1440px);
    }

    &.drawer-sm {
        max-width: 25%;
        width: 25%;
        min-width: rem(480px);
    }

    @media (max-width: $screen-md-min) {
        &, &.drawer-sm, &.drawer-md, &.drawer-lg {
            width: 90%;
            min-width: 1%;
            max-width: 100%;
        }
    }

    &.show { right: 0; }

    .drawer-header {
        padding: $spacing-48 $spacing-48 $spacing-16;

        h1, h2, h3, h4 {
            font: $font-md;
            font-weight: $semibold;
        }

        > *:only-child { margin-bottom: 0; }
    }

    .drawer-body {
        padding: 0 $spacing-48 0;
        margin: $spacing-16 0 $spacing-48;
        height: calc(#{'100% - ' rem(265px)});
        overflow-y: auto;
    }

    .drawer-footer {
        padding: $spacing-32 $spacing-48;
        border-top: rem(1px) solid var(--module-container-divider);
    }

    .close {
        position: absolute;
        top: rem(36px);
        right: rem(52px);
    }
}