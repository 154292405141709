// Cards list - base styles

.card-list {
    .card-list-item {
        justify-content: space-between;
        position: relative;
        overflow: hidden;
        padding: rem(16px) 0;
        margin-left: 0;
        margin-right: 0;

        @media (min-width: $screen-md-min) { flex-flow: nowrap; }

        &:not(:last-child) { margin-bottom: rem(12px); }

        &:hover { cursor: pointer; }

        > * {
            padding: rem(8px) rem(24px);
        }

        &,
        .action,
        .icon,
        .description {
            align-items: center;
            display: flex;
        }

        .icon {
            text-align: center;
            justify-content: center;

            > * {
                min-width: rem(36px);
            }

            > a {
                display: block;
                padding: rem(6px) rem(2px);

                &:hover {
                    background-color: var(--module-container-hover-bg-color);
                }
            }

            .vismaicon + * {
                margin-top: rem(3px);
                display: block;
            }
        }

        .action {
            text-align: center;
        }

        .btn {
            margin-bottom: 0;

            @media (min-width: $screen-md-min) { min-width: rem(120px); }
        }

        .vismaicon {
            top: auto;
            margin-right: rem(12px);
            display: block;
        }

        .description {
            flex-grow: 1;
            flex-shrink: 1;
        }

        .divider {
            border-width: 0 rem(1px);
            border-style: solid;
            border-color: var(--module-container-divider);

            .vismaicon { margin: 0; }

            + .divider { border-left-width: 0; }

            &:first-child { border-left-width: 0; }

            &:last-child { border-left-width: 0; }
        }
    }
}