// Month and year picker

.month-picker {
    .dropdown .dropdown-menu {
        max-width: rem(320px);
        padding-bottom: 0;
    }
}

.datepicker-months {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;

    margin-left: -$spacing-16;
    margin-right: -$spacing-16;

    .datepicker-cell { 
        width: calc(25% - ($spacing-8));
        // width: auto;
        border-radius: $border-radius-10;
        margin: 0 $spacing-16 $spacing-32;
        padding-left: $spacing-8;
        padding-right: $spacing-8;
        text-align: center;

        &:hover { background: var(--datepicker-item-hover-bg); }

        &.is-selected {
            color: var(--datepicker-item-selected-text);
            background: var(--datepicker-item-selected-bg);
            font-weight: 600;
        }
    }
}