// Base style
.datepicker {
    .dropdown {
        margin:0;

        @media(max-width: $screen-xs-min) {
            width: 100%;
        }
    }

    .dropdown-menu {
        width: rem(400px);
        padding: $spacing-24 $spacing-32 $spacing-32 $spacing-32;

        @media(max-width: $screen-xs-min) {
            padding-left: $spacing-16;
            padding-right: $spacing-16;
            width: 100%;
            min-width: rem(265px);
        }

        .dropdown-content {
            background-color: transparent;
            border-radius: 0;
            box-shadow: none;
            padding: 0;
        }
    }

    .dropdown-item {
        &,
        &:hover,
        &:active,
        a:active,
        button:active { background-color: transparent; }

        &:focus { box-shadow: none; }
    }

    .pagination, 
    .pagination-list {
        align-items: center;
        display: flex;
        justify-content: center;
        text-align: center;
        width: 100%;
    }

    .pagination-next,
    .pagination-previous {
        width: $input-btn-height;
        height: $input-btn-height;
        padding: 0;
        margin: 0;
        flex-shrink: 0;

        span {
            @include caret-large-primary();
            display: block;
            position: relative;
            background-color: var(--caret-primary-bg);
            top: rem(12px);
            transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
        }
    }

    .pagination-next {
        order: 3;

        span {
            transform: rotate(-90deg);
            left: rem(11px);
        }
    }

    .pagination-previous {
        order: 1;

        span {
            left: rem(10px);
        }
    }

    .pagination-list {
        flex-grow: 1;
        flex-shrink: 2;
        justify-content: flex-start;
        order: 1;
        flex-wrap: wrap;
        margin: 0;
        margin-top: -1px;
    }

    .pagination.is-centered .pagination-list {
        justify-content: center;
        order: 2;
    }

    .btn {
        border: rem(2px) solid transparent;
        border-radius: $border-radius-20;
        min-width: rem(40px);
        box-shadow: none;
        //padding: 0 rem(12px) rem(1px);
        width: auto;

        &.btn-today {
            border-color: var(--datepicker-today-btn-border);
            color: var(--datepicker-today-btn-text);

            &:focus {
                box-shadow: inset 0 0 0 rem(1px) var(--anchor-focus-outline);
            }
        }

        &.btn-clear {
            border-color: var(--datepicker-clear-btn-border);
            color: var(--datepicker-clear-btn-text);

            &:focus {
                box-shadow: inset 0 0 0 rem(1px) var(--datepicker-clear-btn-border);
            }
        }
    }

    .field-body {
        display: flex;
        flex-basis: 0;
        flex-grow: 5;
        flex-shrink: 1;

        .field.has-addons {
            display: flex;
            justify-content: flex-start;

            
        }
    }

    .datepicker-header {
        .select {
            display: inline-block;
            max-width: 100%;
            position: relative;
            vertical-align: top;

            select {
                border: 0;
                box-shadow: none;
                min-width: 1%;
                padding-right: $spacing-24;

                background: {
                    image: var(--datepicker-arrow);
                    repeat: no-repeat;
                    position: right rem(11px) top rem(14px);
                    color: transparent;
                }

                &:hover { background-color: transparent; }

                &:focus {
                    outline: 0;
                    box-shadow: inset 0 0 0 rem(2px) var(--anchor-focus-outline);
                }
            }
        }

        .control {
            &:first-child {
                select { text-align: right; }
            }
        }
    }

    .datepicker-header,
    .datepicker-body > .datepicker-row {
        display: flex;
        cursor: default;

        .datepicker-cell {
            width: 12.5%;
            text-align: center;
            padding: 0;
            overflow: visible;
            color: var(--datepicker-item-text);

            span {
                display: block;
                width: $input-btn-height;
                height: $input-btn-height;
                line-height: rem(29px);
                margin: 0 auto;
                border-radius: $border-radius-circle;
                border: rem(1px) solid transparent;
            }

            &.is-unselectable {
                color: var(--datepicker-nonclickable-text-color);

                &:hover {
                   &, > span {
                       pointer-events: none;
                       cursor: default;
                    }
                }
            }
        }
    }

    .datepicker-body > .datepicker-row {
        .datepicker-cell {
            position: relative;
            margin-bottom: $spacing-8;

            span {
                position: relative;
                overflow: visible;
                border-color: transparent;
                
                &:before,
                &:after {
                    content:'';
                    display: block;
                    position: absolute;
                    width: 60%;
                    // height: calc(#{'100% + ' rem(2px)});
                    height: $input-btn-height;
                    top: rem(-1px);
                    z-index: -1;
                }
            }

            &.is-week-number {
                border-right: rem(1px) solid var(--datepicker-border-color);
                color: var(--datepicker-nonclickable-text-color);
                cursor: default;
                margin: 0;
                padding-bottom: $spacing-8; 

                span { margin: 0 $spacing-8 0 0; }
            }

            &:not(.is-week-number):not(.is-unselectable):not(.is-nearby) {
                span { font-weight: 600; }
            }

            &:hover {
                &:not(.is-week-number):not(.is-unselectable) span { background: var(--datepicker-item-hover-bg); }

                &:not(.is-week-number):not(.is-unselectable):not(.is-first-hovered):not(.is-last-hovered):not(.is-last-selected):not(.is-first-selected) span { background: var(--datepicker-item-hover-bg); }
            }

            &.is-nearby,
            &.is-unselectable { color: var(--datepicker-nonclickable-text-color); }

            &:focus {
                box-shadow: none;

                &:not(.is-nearby) {
                    span { box-shadow: inset 0 0 0 rem(2px) var(--anchor-focus-outline); }
                }
            }

            &.is-today {
                span { box-shadow: inset 0 0 0 rem(2px) var(--datepicker-item-today-border); }

                &.is-within-selected,
                &.is-within-hovered-range {
                    span { box-shadow: none; }
                }
            }

            &.is-within-hovered-range,
            &.is-selected {
                color: var(--datepicker-item-selected-text);

                &:not(.is-week-number):not(.is-unselectable):not(.is-first-hovered):not(.is-last-hovered):not(.is-last-selected):not(.is-first-selected):not(.is-within-selected):not(.is-within-hovered-range) {
                    &, &:hover {
                        span { background: var(--datepicker-item-selected-bg); }
                    }
                }

                &:not(.is-week-number):not(.is-unselectable):not(.is-within-selected):not(.is-within-hovered-range) {
                    &, &:hover {
                        span { background: var(--datepicker-item-selected-bg); }
                    }
                }

                &:focus {
                    span {
                        box-shadow: none;
                        border: rem(1px) solid var(--datepicker-item-selected-bg);
                        outline: rem(2px) solid var(--anchor-focus-outline);
                        outline-offset: rem(-1px);
                    }
                }

                &.is-within-hovered:not(.is-first-hovered),
                &.is-within-hovered:not(.is-last-hovered),
                &.is-within-selected {
                    background: var(--datepicker-item-selected-range-bg);
                    color: var(--datepicker-item-text);
 
                    span {
                        background: var(--datepicker-item-selected-range-bg);
                        border-radius: 0;
                    }
                }

                &.is-first-hovered,
                &.is-last-hovered,
                &.is-last-selected,
                &.is-first-selected { z-index: 1; }

                &.is-first-hovered { z-index: 2; }
            }

            &.is-within-hovered-range {
                &, &:hover {
                    span { background: var(--datepicker-item-hover-range-bg); }
                }

                &.is-within-hovered:not(.is-first-hovered),
                &.is-within-hovered:not(.is-last-hovered) {
                    background: var(--datepicker-item-hover-range-bg);
                    color: var(--datepicker-item-text);
 
                    span { background: var(--datepicker-item-hover-range-bg); }
                }
            }

            &.is-last-hovered.is-within-hovered-range,
            &.is-first-hovered.is-within-hovered-range {
                color: var(--datepicker-item-selected-text);

                &,
                &.is-selected.is-within-selected,
                &:hover {
                    span { background: var(--datepicker-item-selected-bg); }
                }

                &.is-selected.is-within-selected {
                    span { border-radius: 100%; }
                }

                &:focus {
                    span {
                        box-shadow: inset 0 0 0 rem(2px) var(--datepicker-item-selected-focus-border);
                        border: 1px solid var(--datepicker-item-selected-bg);
                    }
                }
            }

            &.is-selected {
                &.is-first-selected,
                &.is-first-selected.is-last-hovered.is-within-hovered-range {
                    span:after {
                        right: -20%;
                        left: auto;
                        background: var(--datepicker-item-selected-range-bg);
                    }
                }

                &.is-first-selected.is-last-hovered.is-within-hovered-range {
                    span:before {
                        left: -20%;
                        right: auto;
                        background: var(--datepicker-item-hover-range-bg);
                    }
                }

                &.is-first-selected.is-within-hovered-range:not(.is-last-hovered):not(.is-first-hovered),
                &.is-first-selected.is-within-hovered:not(.is-last-hovered):not(.is-first-hovered),
                &.is-last-selected.is-within-hovered-range:not(.is-last-hovered):not(.is-first-hovered),
                &.is-last-selected.is-within-hovered:not(.is-last-hovered):not(.is-first-hovered) {
                    span:before,
                    span:after { display: none; }
                }

                &.is-last-selected {
                    span:before {
                        left: -20%;
                        right: auto;
                        background: var(--datepicker-item-selected-range-bg);
                    }

                    &.is-within-hovered-range.is-last-hovered {
                        span:before {
                            left: -25%;
                            right: auto;
                            background: var(--datepicker-item-hover-range-bg);
                        }

                        &.is-first-hovered {
                            span:before {
                                background: var(--datepicker-item-selected-range-bg);
                            }
                        }
                    }
                }
            }

            &.is-within-hovered-range {
                &.is-first-hovered {
                    span:after {
                        right: -20%;
                        background: var(--datepicker-item-hover-range-bg);
                    }

                    &.is-last-hovered {
                        span:after { display: none; }
                    }
                }

                &.is-last-hovered {
                    span:after {
                        left: -20%;
                        background: var(--datepicker-item-hover-range-bg);
                    }
                }
            }

            &.is-selected.is-first-selected.is-last-selected {
                span:before,
                span:after { display: none; }

                // &,
                &.is-within-hovered-range.is-first-hovered {
                    span:before { display: none; }
                    span:after { display: block; }
                }

                &.is-within-hovered-range.is-last-hovered {
                    span:after { display: none; }
                    span:before { display: block; }
                }

                &.is-first-hovered.is-last-hovered {
                    span:before,
                    span:after { display: none; }
                }
            }

            &.is-first-hovered.is-last-hovered {
                &.is-first-selected {
                    span:before { display: none; }
                    span:after { display: block; }
                }
            }
        } // .datepicker-cell

        &:last-child {
            .datepicker-cell.is-week-number { padding-bottom: 0; }

            .datepicker-cell { margin-bottom: 0; }
        }
    }

    .datepicker-header {
        .datepicker-cell {
            color: var(--datepicker-nonclickable-text-color);
            cursor: default;
            font-size: $font-size-sm;

            span { 
                line-height: 1;
                height: rem(14px);
                margin-bottom: $spacing-16;
            }

            &:first-child {
                border-right: 1px solid var(--datepicker-border-color);

                span {
                    &:before { content:'Wk'; }
                    margin: 0 $spacing-8 $spacing-16 0;
                }
            }
        }
    }

    .datepicker-table { padding-top: $spacing-16; }

    .datepicker-footer {
        padding: $spacing-24 0 0 0;
        display: flex;
        justify-content: space-between;
        height: auto;
        width: auto;
        bottom: auto;
        position: relative;
    }
}
